import React from "react";
import Chart from "chart.js";

import {
  Button,
  Card,
  CardHeader,
  Progress,
  Table,
  Container,
  Row,
  Col
} from "reactstrap";

// core components
import {
  chartOptions,
  parseOptions,
} from "variables/charts.js";
import { API_ISE } from "../variables/config"
import Header from "components/Headers/Header";
import { Link } from "react-router-dom";

class Index extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      activeNav: 1,
      chartExample1Data: "data1",
      cat : "",
      formations : "",
      references : "",
      formData : [],
      catData : []
    };
    if (window.Chart) {
      parseOptions(Chart, chartOptions());
    }
  }
 async componentDidMount(){
  
 
 await fetch(API_ISE+"/formations/all-stats")
      .then(res => res.json())
      .then(
        (result) => {
          console.log(result)
          this.setState({
            cat : result.c,
            formations : result.f,
            references : result.r,
            formData : result.formData,
            catData : result.categorie
          })
        },
      )
  }

  toggleNavs = (e, index) => {
    e.preventDefault();
    this.setState({
      activeNav: index,
      chartExample1Data:
        this.state.chartExample1Data === "data1" ? "data2" : "data1"
    });
  };
  render() {
    console.log(this.state);
    const { formData, catData } = this.state;
    return (
      <>
        <Header form={this.state.formations} cat={this.state.cat} reference={this.state.references}/>
        {/* Page content */}
        <Container className="mt--7" fluid>
          <Row className="mt-5">
            <Col className="mb-5 mb-xl-0" xl="8">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-0">Formations</h3>
                    </div>
                    <div className="col text-right">
                      <Link
                        className="btn-danger btn btn-sm"
                        to="/admin/formations"
                      >
                        Toutes les formations
                      </Link>
                    </div>
                  </Row>
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Titre</th>
                      <th scope="col">Durée</th>
                      <th scope="col">Référence</th>
                    </tr>
                  </thead>
                  <tbody>
                    {formData.map((item, i)=>{
                      return(
                        <tr key={i}>
                          <th scope="row">{item.TitleLabel}</th>
                          <td>{item.Duree}</td>
                          <td>{item.RefLabel}</td>
                        </tr>
                      )
                    })}
                  </tbody>
                </Table>
              </Card>
            </Col>
            <Col xl="4">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-0">Catégories</h3>
                    </div>
                    <div className="col text-right">
                      <Button
                        className="btn-info btn btn-sm"
                        to="/admin/categories"
                      >
                        Toutes les catégories
                      </Button>
                    </div>
                  </Row>
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Referral</th>
                    </tr>
                  </thead>
                  <tbody>
                    {catData.map((item, i)=>{
                      return(
                        <tr key={i}>
                      <th scope="row">{item.CatLabel}</th>
                      
                    </tr>
                      )
                    })}
                  </tbody>
                </Table>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default Index;

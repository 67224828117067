import React from "react";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Button
} from "reactstrap";
import CreatableSelect from 'react-select/creatable';
// core components
import Header from "components/Headers/Header.js";
import { Link } from "react-router-dom";
import { API_ISE } from "../../variables/config"
import Label from "reactstrap/lib/Label";
import Col from "reactstrap/lib/Col";
import Select from "react-select";

class Icons extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      Attestation: false,
      Categories: "",
      Duree: "",
      EvolutionLabel: "",
      LinkFile: "",
      ModeLabel: "",
      MoyenPed : "",
      Objectifs: {Content: [], TitleLabel: "Nos objectifs pédagogiques", SubTitleLabel: "A l’issue de cette formation, vous serez capable de :"},
      PSubTitleLabel: "", PContent: "", PSubTitleLabel2: "",  PContent2: "",
      Psh: true,
      RefLabel: "",
      SubTitleLabel: "",
      TitleLabel: "",
      _id: "",
      activeNav: 1,
      chartExample1Data: "data1",
      cat : "",
      formations : "",
      references : "",
      formData : [],
      catData : [],
      ObjectifsPeda : "",
      MatinContent : "",
      MatinTitle : "",
      MidiContent : "",
      MidiTitle : "",
      MatinValue : [],
      MidiValue : [],
      Matin : [],
      ApresMidi : [],
      M : [],
      Apm : []
    };
  }
  handleChangeRegion = (region) => {
    console.log(region.value)
    this.setState({Categories : region.value})
  }
  handleChange = (e) => {
    console.log(e);
    this.setState({MatinValue : e})
  };
  handleChangeMidi = (e) => {
    console.log(e);
    this.setState({MidiValue : e})
  };
  handleText = i => e => {
    let questions = [...this.state.M];
    questions[i] = e.target.value;
    this.setState({
      M : questions
    });
  };
  handleDelete = i => e => {
    e.preventDefault();
    let questions = [
      ...this.state.M.slice(0, i),
      ...this.state.M.slice(i + 1)
    ];
    this.setState({
      M : questions
    });
  };

  addQuestion = e => {
    e.preventDefault();
    let questions = this.state.M.concat([""]);
    this.setState({
      M : questions
    });
  };
  handleTextB = i => e => {
    let questions = [...this.state.Apm];
    questions[i] = e.target.value;
    this.setState({
      Apm : questions
    });
  };
  handleDeleteB = i => e => {
    e.preventDefault();
    let questions = [
      ...this.state.Apm.slice(0, i),
      ...this.state.Apm.slice(i + 1)
    ];
    this.setState({
      Apm : questions
    });
  };

  addQuestionB = e => {
    e.preventDefault();
    let questions = this.state.Apm.concat([""]);
    this.setState({
      Apm : questions
    });
  };
  // ValideMidi = () => {
  //   var dataMidi = [];
  //   var dataValue = [...this.state.ApresMidi];
  //   for (let i = 0; i < this.state.MidiValue.length; i++) {
  //     const element = this.state.MidiValue[i];
  //     dataValue.push(element.value)
  //     dataMidi.push({
  //       Title : this.state.MidiTitle, Content : dataValue
  //     })
  //   }
  //   this.setState({
  //     ApresMidi :  dataMidi
  //   })
  //   console.log(this.state.ApresMidi);
  // }
  
  ValideMidi = () => {
    var dataMidi = [...this.state.ApresMidi];
    dataMidi.push({
      Title : this.state.MidiTitle, Content : this.state.Apm
    })
    this.setState({
      ApresMidi :  dataMidi, Apm : [], MidiTitle : ""
    })
    console.log(this.state.ApresMidi);
  }
  ValideMatin = () => {
    var dataMatin = [...this.state.Matin];
    dataMatin.push({
      Title : this.state.MatinTitle, Content : this.state.M
    })
    this.setState({
      Matin :  dataMatin, M : [], MatinTitle : ""
    })
    console.log(this.state.Matin);
  }
 async componentDidMount(){
 await fetch(API_ISE+"/formations/all-stats")
      .then(res => res.json())
      .then(
        (result) => {
          console.log(result)
          var cat = []
          for (let i = 0; i < result.categorie.length; i++) {
            const element = result.categorie[i];
            cat.push({
              value : element._id,
              label : element.CatLabel
            })
          }
          this.setState({
            cat : result.c,
            formations : result.f,
            references : result.r,
            formData : result.formData,
            catData : cat
          })
        },
      )
  }
  dataEdit=()=>{
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ 
          Attestation: false,
          Categories: this.state.Categories,
          Duree: this.state.Duree,
          EvolutionLabel: this.state.EvolutionLabel,
          LinkFile: this.state.LinkFile,
          ModeLabel: this.state.ModeLabel,
          MoyenPed : this.state.MoyenPed,
          Objectifs: {Content: this.state.Objectifs.Content, TitleLabel: "Nos objectifs pédagogiques", SubTitleLabel: "A l’issue de cette formation, vous serez capable de :"},
          Presentation: {
            SubTitleLabel: this.state.PSubTitleLabel,
            Content: this.state.PContent,
            SubTitleLabel2: this.state.PSubTitleLabel2,
            Content2: this.state.PContent2,
          },
          Psh: true,
          RefLabel: this.state.RefLabel,
          SubTitleLabel: this.state.SubTitleLabel,
          TitleLabel: this.state.TitleLabel,
          _id: this.state._id,
          ProgrammeMatin: this.state.Matin,
      ProgrammeMidi: this.state.ApresMidi,
      Commentaires: []
       })
    };
    fetch(API_ISE+"/formations/add-formation/", requestOptions)
        .then(response => response.json())
        .then(data => this.props.history.push('/admin/formations'));
  }
  // dataEdit=()=>{
  //   console.log(this.state)
  //   alert('Enregistré')
  //   // this.props.history.push('/admin/formations')
  // }
  render() {
    const { catData, Objectifs, ObjectifsPeda, Matin,ApresMidi, MatinContent, MatinTitle, MidiTitle,MidiContent,MatinValue  } = this.state;
    console.log(Matin)
    return (
      <>
        <Header
          form={this.state.formations}
          cat={this.state.cat}
          reference={this.state.references}
        />
        {/* Page content */}
        <Container className=" mt--7" fluid>
          {/* Table */}
          <Row>
            <div className=" col">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-0">Nouvelle fiche</h3>
                    </div>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Form role="form">
                    <Row>
                      <FormGroup className="col-lg-4">
                        <Label for="TitleLabel">Titre</Label>
                        <Input
                          type="text"
                          name="TitleLabel"
                          placeholder="Titre"
                          id="TitleLabel"
                          onChange={(e) =>
                            this.setState({ TitleLabel: e.target.value })
                          }
                        />
                      </FormGroup>
                      <FormGroup className="col-lg-4">
                        <Label for="SubTitleLabel">Sous Titre</Label>
                        <Input
                          type="text"
                          name="SubTitleLabel"
                          placeholder="Sous titre"
                          id="SubTitleLabel"
                          onChange={(e) =>
                            this.setState({ SubTitleLabel: e.target.value })
                          }
                        />
                      </FormGroup>
                      <FormGroup className="col-lg-4">
                        <Label for="RefLabel">Référence</Label>
                        <Input
                          type="text"
                          name="RefLabel"
                          placeholder="Référence"
                          id="RefLabel"
                          onChange={(e) =>
                            this.setState({ RefLabel: e.target.value })
                          }
                        />
                      </FormGroup>
                    </Row>
                    <Row>
                      <FormGroup className="col-lg-4">
                        <Label for="categorie">Catégorie</Label>
                        <Select
                          onChange={this.handleChangeRegion}
                          placeholder="Séléctionnez une catégorie"
                          options={catData}
                        />
                      </FormGroup>
                      <FormGroup className="col-lg-4">
                        <Label for="RefLabel">Présentiel ou Distanciel</Label>
                        <Input
                          type="text"
                          name="RefLabel"
                          placeholder="Présentiel ou Distanciel"
                          id="RefLabel"
                          onChange={(e) =>
                            this.setState({ ModeLabel: e.target.value })
                          }
                        />
                      </FormGroup>
                      <FormGroup className="col-lg-4">
                        <Label for="Duree">Durée</Label>
                        <Input
                          type="text"
                          name="Duree"
                          placeholder="Durée"
                          id="Duree"
                          onChange={(e) =>
                            this.setState({ Duree: e.target.value })
                          }
                        />
                      </FormGroup>
                    </Row>
                    <Row>
                      <FormGroup className="col-lg-4">
                        <Label for="EvolutionLabel">Evolution</Label>
                        <Input
                          type="text"
                          name="EvolutionLabel"
                          placeholder="Evolution, fondamental..."
                          id="EvolutionLabel"
                          onChange={(e) =>
                            this.setState({ EvolutionLabel: e.target.value })
                          }
                        />
                      </FormGroup>
                      <FormGroup className="col-lg-4">
                        <Label for="LinkFile">Lien Google Drive</Label>
                        <Input
                          type="text"
                          name="EvolutionLabel"
                          placeholder="Lien Google Drive"
                          id="LinkFile"
                          onChange={(e) =>
                            this.setState({ LinkFile: e.target.value })
                          }
                        />
                      </FormGroup>
                    </Row>
                    <Row>
                      <FormGroup className="col-lg-4">
                        <Label for="SubTitleLabel">
                        En tant qu’élu...
                        </Label>
                        <Input
                          type="text"
                          name="SubTitleLabel"
                          placeholder="En tant qu’élu..."
                          id="SubTitleLabel"
                          onChange={(e) =>
                            this.setState({ PSubTitleLabel: e.target.value })
                          }
                        />
                      </FormGroup>
                      <FormGroup className="col">
                        <Label for="Content">Contenu en tant que...</Label>
                        <Input
                          type="textarea"
                          name="Content"
                          placeholder="Contenu en tant que..."
                          id="Content"
                          onChange={(e) =>
                            this.setState({ PContent: e.target.value })
                          }
                        />
                      </FormGroup>
                    </Row>
                    <Row>
                      <FormGroup className="col-lg-4">
                        <Label for="SubTitleLabelé">
                          Cette formation vous permettra de...
                        </Label>
                        <Input
                          type="text"
                          name="SubTitleLabel2"
                          placeholder="Cette formation vous permettra de..."
                          id="SubTitleLabel"
                          onChange={(e) =>
                            this.setState({ PSubTitleLabel2: e.target.value })
                          }
                        />
                      </FormGroup>
                      <FormGroup className="col">
                        <Label for="Content">
                          Contenu Cette formation vous permettra de...
                        </Label>
                        <Input
                          type="textarea"
                          name="Content"
                          placeholder="Contenu Cette formation vous permettra de..."
                          id="Content"
                          onChange={(e) =>
                            this.setState({ PContent2: e.target.value })
                          }
                        />
                      </FormGroup>
                      <FormGroup className="col">
                        <Label for="Content">
                          Moyens techniques et méthodes pédagogiques
                        </Label>
                        <Input
                          type="textarea"
                          name="Content"
                          placeholder="Moyens techniques et méthodes pédagogiques"
                          id="Content"
                          onChange={(e) =>
                            this.setState({ MoyenPed: e.target.value })
                          }
                        />
                      </FormGroup>
                    </Row>
                    <Col>
                      <Row>
                        <FormGroup className="col-lg-4">
                          <Label for="SubTitleLabelé">
                            Nos objectifs pédagogiques
                          </Label>
                          <Input
                            type="text"
                            name="SubTitleLabel2"
                            placeholder="Objectif..."
                            id="SubTitleLabel"
                            value={ObjectifsPeda}
                            onChange={(e) =>
                              this.setState({ ObjectifsPeda: e.target.value })
                            }
                          />

                          <Button
                            className="mt-4 mb-4"
                            color="success"
                            type="button"
                            onClick={(e) => {
                              e.preventDefault();
                              Objectifs.Content.push(ObjectifsPeda);
                              this.setState({ ObjectifsPeda: "", state: this.state });
                              
                            }}
                          >
                            + Ajouter
                          </Button>
                        </FormGroup>
                        <ul className="col-6">
                          {Objectifs.Content.map((data, i) => {
                            return (
                              <li className="d-flex" key={i}>
                                <Button
                                  className="btn-small p-1 mr-3"
                                  color="danger"
                                  type="button"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    Objectifs.Content.splice(i, 1);
                                    this.setState({ state: this.state });
                                  }}
                                >
                                  X
                                </Button>{" "}
                                {data}{" "}
                              </li>
                            );
                          })}
                        </ul>
                      </Row>
                    </Col>
                    <Col>
                      <Row>
                        <FormGroup className="col-lg-4 ">
                          <Label for="SubTitleLabelé">Programme du matin</Label>
                          <Input
                            type="text"
                            name="MatinTitle"
                            placeholder="Titre..."
                            id="Matin"
                            value={MatinTitle}
                            onChange={(e) =>
                              this.setState({ MatinTitle: e.target.value })
                            }
                          />
                          {this.state.M.map((question, index) => (
                            <span key={index}>
                              <Input
                                type="text"
                                onChange={this.handleText(index)}
                                value={question}
                              />
                              <Button onClick={this.handleDelete(index)}>
                                X
                              </Button>
                            </span>
                          ))}
                          <Button
                            className="btn-small p-1 mr-3"
                            color="info"
                            type="button"
                            onClick={this.addQuestion}
                          >
                            Ajouter une ligne
                          </Button>
                          <Button
                            className="mt-4 mb-4"
                            color="success"
                            type="button"
                            onClick={this.ValideMatin}
                          >
                            Valider
                          </Button>
                        </FormGroup>

                        <ul>
                          {Matin.map((data, i) => {
                            console.log(data);
                            return (
   
                              <li className="d-flex" key={i}>
                                <Button
                                  className="btn-small p-1 mr-3"
                                  color="danger"
                                  type="button"
                                  onClick={() => {this.setState({ state: this.state }); Matin.splice(i, 1)}}
                                >
                                  X
                                </Button>{" "}
                                {data.Title}{" "}
                                <ul>
                                
                                {data.Content.map((item, b) => {
                                  return(

                                    <li key={b}>{item}</li>
                                  )
                                })}
                            </ul>
                              </li>
                            );
                          })}
                          {/* {Matin.Content.map((data, i)=>{
                          return(
                            <li className="d-flex" key={i}><Button className="btn-small p-1 mr-3" color="danger" type="button" onClick={()=>Objectifs.splice(i, 1)}>X</Button> {data} </li>
                          )
                        })} */}
                        </ul>
                      </Row>
                    </Col>
                    <div>
                      <Col>
                        <Row>
                          <FormGroup className="col-lg-4">
                            <Label for="SubTitleLabelé">
                              Programme Après midi
                            </Label>
                            <Input
                              type="text"
                              name="MidiTitle"
                              placeholder="Titre..."
                              id="Matin"
                              value={MidiTitle}
                              onChange={(e) =>
                                this.setState({ MidiTitle: e.target.value })
                              }
                            />
                            {this.state.Apm.map((question, index) => (
                              <>
                                <Input
                                  key={index}
                                  type="text"
                                  onChange={this.handleTextB(index)}
                                  value={question}
                                />
                                <Button onClick={this.handleDeleteB(index)}>
                                  X
                                </Button>
                              </>
                            ))}
                            <Button
                              className="btn-small p-1 mr-3"
                              color="info"
                              type="button"
                              onClick={this.addQuestionB}
                            >
                              Ajouter une ligne
                            </Button>
                            {/* <Input
                          type="text"
                          className="mt-2"
                          name="MidiContent"
                          placeholder="Matin1, Matin2, ..."
                          value={MidiContent}
                          onChange={(e)=> this.setState({MidiContent: e.target.value})}
                        /> */}
                            {/* <CreatableSelect
                          isMulti
                          onChange={this.handleChangeMidi}
                          placeholder="Saisir une ligne + touche entrer"
                        /> */}
                            <Button
                              className="mt-4 mb-4"
                              color="success"
                              type="button"
                              onClick={this.ValideMidi}
                            >
                              Valider
                            </Button>
                          </FormGroup>
                          <ul className="col-6">
                            {ApresMidi.map((data, i) => {
                              console.log(data);
                              return (
                                <li className="d-flex" key={i}>
                                <Button
                                  className="btn-small p-1 mr-3"
                                  color="danger"
                                  type="button"
                                  onClick={() => {this.setState({ state: this.state }); ApresMidi.splice(i, 1)}}
                                >
                                  X
                                </Button>{" "}
                                {data.Title}{" "}
                                <ul>
                                
                                {data.Content.map((item, b) => {
                                  return(

                                    <li key={b}>{item}</li>
                                  )
                                })}
                            </ul>
                              </li>
                              );
                            })}
                          </ul>
                        </Row>
                      </Col>
                    </div>
                  </Form>
                  <Button
                    className="mt-4"
                    color="warning"
                    type="button"
                    onClick={() => this.dataEdit()}
                  >
                    Enregistrer
                  </Button>
                </CardBody>
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

export default Icons;


import React from "react";
// react component that copies the given text inside your clipboard
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Button
} from "reactstrap";
import Select from "react-select";
// core components
import Header from "components/Headers/Header.js";
import { API_ISE } from "../../variables/config"
import Label from "reactstrap/lib/Label";
import Datetime from 'react-datetime';
import 'moment/locale/fr';
class EditSession extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      Dates : props.location.state.data.Dates,
      Status : true,
      res : [],
      Fiche: "",
      Formation : props.location.state.data.Formation,
      Regions : props.location.state.data.Regions,
      Places : props.location.state.data.Places
    };
    this.dataEdit = this.dataEdit.bind(this)
  }
  async componentDidMount() {
    await fetch(API_ISE + "/formations/all-stats")
      .then((res) => res.json())
      .then((result) => {
        console.log(result);
        var res = []
        for (let i = 0; i < result.formData.length; i++) {
          const element = result.formData[i];
          res.push({
            label : element.RefLabel + ' - ' + element.TitleLabel ,
            value : element._id
          })
        }
        console.log(res)
         this.setState({
           res
         })
      });
  }
  handleChangeFormation = (formation) => {
    console.log(formation);
    this.setState({ Fiche: formation.value });
  };
handleChangeRegion = (region) => {
      console.log(region)
      this.setState({Regions : region.label})
    }
  dataEdit=()=>{
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ 
        Dates : this.state.Dates,
        Status : true,
        Regions : this.state.Regions,
        Formation : this.state.Formation,
        Places : this.state.Places,
        Fiche : this.state.Fiche,
        EventID : this.props.location.state.data._id
       })
    };
    fetch(API_ISE+"/formations/edit-event/", requestOptions)
        .then(response => response.json())
        .then(data => this.props.history.push('/admin/dates'));
  }

  render() {
    console.log(this.props.location.state.data)
    return (
      <>
        <Header
          form={this.state.formations}
          cat={this.state.cat}
          reference={this.state.references}
        />
        {/* Page content */}
        <Container className=" mt--7" fluid>
          {/* Table */}
          <Row>
            <div className=" col">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-0">Nouvelle session</h3>
                    </div>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Form role="form">
                    <Row>
                      <FormGroup className="col-lg-4">
                        <Label for="TitleLabel">Région</Label>
                        <Select
                                      onChange={this.handleChangeRegion}
                                      placeholder="Séléctionnez une région"
                                      options={categories}
                                      required={true}
                                    />
                      </FormGroup>
                      <FormGroup className="col-lg-4">
                        <Label for="Formation">Séléctionnez une fiche formation</Label>
                        <Select
                          onChange={this.handleChangeFormation}
                          placeholder="Séléctionnez une fiche"
                          options={this.state.res}
                          required={true}
                        />
                      </FormGroup>
                      <div className="col-lg-4">
                        <Label for="TitleLabel">Dates</Label>
                        <Datetime
                          locale="fr-fr"
                          inputProps={{
                            placeholder: "Sélectionnez une date",
                          }}
                          className="w-100"
                          timeFormat={false}
                          onChange={(e) => this.setState({ Dates: e })}
                        />
                        {/* <Input
                          type="text"
                          name="Dates"
                          placeholder="Dates"
                          id="TitleLabel"
                          defaultValue={this.state.Dates}
                          onChange={(e) =>
                            this.setState({ Dates: e.target.value })
                          }
                        /> */}
                      </div>
                      <FormGroup className="col-lg-4">
                        <Label for="TitleLabel">Places maximum</Label>
                        <Input
                          type="number"
                          name="Lieu"
                          placeholder="Nombre de places"
                          id="TitleLabel"
                          defaultValue={this.state.Places}
                          onChange={(e)=>this.setState({Places : e.target.value})}
                        />
                      </FormGroup>
                      <FormGroup className="col-lg-4">
                        <Label for="SubTitleLabel">Descrition ou Titre</Label>
                        <Input
                          type="text"
                          name="SubTitleLabel"
                          placeholder="formation"
                          id="SubTitleLabel"
                          defaultValue={this.state.Formation}
                          onChange={(e)=>this.setState({Formation : e.target.value})}
                        />
                      </FormGroup>
                      
                    </Row>
                    <div className="text-center">
                      <Button className="mt-4" color="success" type="button" onClick={()=> this.dataEdit()}>
                        Enregistrer
                      </Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}
const categories = [

  {
      value: 0,
      label: 'Sélectionnez une région'
  },
  {
      value: 1,
      label: 'Toute la france'
  },
  {
      value: 2,
      label: 'Auvergne-Rhône-Alpes'
  },
  {
      value: 3,
      label: 'Bretagne'
  },
  {
      value: 4,
      label: 'Centre-Val de Loire'
  },
  {
      value: 5,
      label: 'Corse'
  },
  {
      value: 6,
      label: 'Grand Est'
  },
  {
      value: 7,
      label: 'Hauts-de-France'
  },
  {
      value: 8,
      label: 'Ile-de-France'
  },
  {
      value: 9,
      label: 'Normandie'
  },
  {
      value: 10,
      label: 'Nouvelle-Aquitaine'
  },
  
  {
      value: 11,
      label: 'Occitanie'
  },
  
  {
      value: 12,
      label: 'Pays de la Loire'
  },
  
  {
      value: 13,
      label: 'Provence-Alpes-Côte d’Azur'
  },
  
  {
      value: 14,
      label: 'Guadeloupe'
  },
  
  {
      value: 15,
      label: 'Martinique'
  },
  
  {
      value: 16,
      label: 'Mayotte'
  },
  
  {
      value: 17,
      label: 'Nouvelle-Calédonie'
  },
  {
      value: 18,
      label: 'Polynésie française'
  },
  {
      value: 19,
      label: 'La Réunion'
  },
  {
      value: 20,
      label: 'Saint-Pierre et Miquelon'
  },
  {
      value: 21,
      label: 'Saint-Martin'
  },
  {
      value: 22,
      label: 'Saint-Barthélemy'
  },


]
export default EditSession;

/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// reactstrap components
import {
  Card,
  CardHeader,
  Container,
  Row,
  Table,
  Media,
  Button
} from "reactstrap";
// core components
import Header from "components/Headers/UserHeader";
import { Link } from "react-router-dom";
import { API_ISE } from "../../variables/config"

class Icons extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      activeNav: 1,
      chartExample1Data: "data1",
      cat : "",
      formations : "",
      references : "",
      formData : [],
      catData : [],
      formateurs : [],
    };
  }
  deleteForm =(e)=>{
    console.log(e)
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ 
        FormateurID : e._id
     })
  };
  fetch(API_ISE+"/formateurs/delete/", requestOptions)
      .then(response => response.json())
      .then(data => alert("Formateur supprimé"));
  }

  HandleDif(ItemID, Dif) {
    console.log(ItemID, Dif)
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ 
        FormID : ItemID,
        Dif : !Dif
     })
  };
  fetch(API_ISE+"/formations/updateDif/", requestOptions)
      .then(response => response.json())
      .then(data => this.getData());
  }
  HandleUne(ItemID, Une) {
    console.log(ItemID, Une)
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ 
        FormID : ItemID,
        Une : !Une
     })
  };
  fetch(API_ISE+"/formations/updateUne/", requestOptions)
      .then(response => response.json())
      .then(data => this.getData());
  }

  async getData(){
    await fetch(API_ISE + "/formateurs/")
      .then((res) => res.json())
      .then((result) => {
        console.log(result);
        this.setState({
          formateurs : result
        });
      });
  }
  componentDidMount(){
  
 this.getData()
 
  }

  render() {
    const { formData, formateurs } = this.state;
    return (
      <>
        <Header
          form={this.state.formations}
          cat={this.state.cat}
          reference={this.state.references}
        />
        {/* Page content */}
        <Container className=" mt--7" fluid>
          {/* Table */}
          <Row>
            <div className=" col">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-0">
                        Formateurs | Institut Supérieur des Élus
                      </h3>
                    </div>
                    <div className="col text-right">
                      <Link
                        className="btn-success btn btn-sm"
                        to="/admin/ajouter-formateur"
                      >
                        Ajouter un formateur
                      </Link>
                    </div>
                  </Row>
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Nom/Prénom</th>
                     
                      <th scope="col">Fonction</th>
                      <th scope="col">Mairie</th>
                      <th scope="col">Inter</th>
                      <th scope="col">Déparement</th>
                      <th scope="col">Région</th>
                      <th scope="col">Supprimer</th>
                    </tr>
                  </thead>
                  <tbody>
                    {formateurs.map((item, i) => {
                      return (
                        <tr key={i}>
                          <td>
                          <Media className="align-items-center">
                            <span className="avatar avatar-sm rounded-circle">
                      <img
                        alt="..."
                        src={item.Avatar}
                      />
                    </span>
                       </Media>
                    </td>
                          <td>{item.Nom +" " +item.Prenom}</td>
                          <td>{item.Fonction}</td>
                          <td>
                            <div className="custom-control custom-control-alternative custom-checkbox">
                              <input
                                className="custom-control-input border-dark"
                                id={item._id}
                                type="checkbox"
                                checked={item.Mairie ? true : false}
                                onChange={() =>
                                  this.HandleDif(item._id, item.Mairie)
                                }
                              />
                              <label
                                className="custom-control-label mb-2"
                                htmlFor={item._id}
                              >
                                <span className="text-muted"></span>
                              </label>
                            </div>
                          </td>
                          <td>
                            <div className="custom-control custom-control-alternative custom-checkbox">
                              <input
                                className="custom-control-input border-dark"
                                id={item._id}
                                type="checkbox"
                                checked={item.Inter ? true : false}
                                onChange={() =>
                                  this.HandleDif(item._id, item.Inter)
                                }
                              />
                              <label
                                className="custom-control-label mb-2"
                                htmlFor={item._id}
                              >
                                <span className="text-muted"></span>
                              </label>
                            </div>
                          </td>
                          <td>
                            <div className="custom-control custom-control-alternative custom-checkbox">
                              <input
                                className="custom-control-input border-dark"
                                id={item._id}
                                type="checkbox"
                                checked={item.Departement ? true : false}
                                onChange={() =>
                                  this.HandleDif(item._id, item.Departement)
                                }
                              />
                              <label
                                className="custom-control-label mb-2"
                                htmlFor={item._id}
                              >
                                <span className="text-muted"></span>
                              </label>
                            </div>
                          </td>
                          <td>
                            <div className="custom-control custom-control-alternative custom-checkbox">
                              <input
                                className="custom-control-input border-dark"
                                id={i}
                                type="checkbox"
                                checked={item.Region ? true : false}
                                onChange={() =>
                                  this.HandleUne(item._id, item.Region)
                                }
                              />
                              <label
                                className="custom-control-label mb-2"
                                htmlFor={i}
                              >
                                <span className="text-muted"></span>
                              </label>
                            </div>
                          </td>
                          <td>
                            <Button
                              className="btn-danger btn btn-sm"
                              onClick={() => this.deleteForm(item)}
                            >
                              Supprimer
                            </Button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

export default Icons;

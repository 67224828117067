
import Index from "views/Index.js";
import Profile from "views/examples/Profile.js";
import Tables from "views/examples/Tables.js";
import Formations from "views/examples/Formations.js";
import AddFormation from "views/examples/Add-Formation";
import AddReference from "views/examples/Add-Reference";
import AddCategorie from "views/examples/Add-Categorie";
import EditCategorie from "views/examples/Edit-Categorie";
import EditFormation from "views/examples/Edit-Formation";
import EditRef from "views/examples/Edit-Ref";
import Session from "views/examples/Dates";
import EditSession from "views/examples/Edit-Dates";
import AddSession from "views/examples/Add-Dates";
import Login from "views/examples/Login";
import Avis from "views/examples/Avis";
import Vod from "views/videos/index";
import AddVod from "views/videos/Add-Vod";
import Formateurs from "views/formateurs/index";
import Clients from "views/clients/index";
import ClientsAdd from "views/clients/Add";
import FormateursAdd from "views/formateurs/Add";
import Produits from "views/produits/index";
import AddProduit from "views/produits/Add-Vod";
import Remise from "views/remises/index";
import AddRemise from "views/remises/Add";
import Commandes from "views/commandes/index";

var routes = [
  {
    path: "/index",
    name: "Accueil",
    icon: "ni ni-tv-2 text-red",
    component: Index,
    invisible : true,
    layout: "/admin"
  },
  
  {
    path: "/ajouter-formation",
    name: "Ajouter une formation",
    icon: "ni ni-planet text-red",
    component: AddFormation,
    invisible : false,
    layout: "/admin"
  },
  {
    path: "/avis",
    name: "Ajouter une formation",
    icon: "ni ni-planet text-red",
    component: Avis,
    invisible : false,
    layout: "/admin"
  },
  {
    path: "/modifier-formation",
    name: "Ajouter une formation",
    icon: "ni ni-planet text-red",
    component: EditFormation,
    invisible : false,
    layout: "/admin"
  },
  {
    path: "/ajouter-categorie",
    name: "Ajouter une formation",
    icon: "ni ni-planet text-red",
    component: AddCategorie,
    invisible : false,
    layout: "/admin"
  },
  {
    path: "/modifier-categorie",
    name: "modifier une formation",
    icon: "ni ni-planet text-red",
    component: EditCategorie,
    invisible : false,
    layout: "/admin"
  },
  {
    path: "/ajouter-une-reference",
    name: "Ajouter une formation",
    icon: "ni ni-planet text-red",
    component: AddReference,
    invisible : false,
    layout: "/admin"
  },
  {
    path: "/modifier-une-reference",
    name: "Ajouter une formation",
    icon: "ni ni-planet text-red",
    component: EditRef,
    invisible : false,
    layout: "/admin"
  },
  {
    path: "/categories",
    name: "Catégories",
    icon: "ni ni-books text-red",
    component: Profile,
    invisible : true,
    layout: "/admin"
  },
  {
    path: "/formations",
    name: "Formations",
    icon: "ni ni-paper-diploma text-red",
    component: Formations,
    invisible : true,
    layout: "/admin"
  },
  {
    path: "/dates",
    name: "Dates",
    icon: "ni ni-calendar-grid-58 text-red",
    component: Session,
    invisible : true,
    layout: "/admin"
  },
  {
    path: "/ajouter-session",
    name: "Dates",
    icon: "ni ni-calendar-grid-58 text-red",
    component: AddSession,
    invisible : false,
    layout: "/admin"
  },
  {
    path: "/modifier-session",
    name: "Dates",
    icon: "ni ni-calendar-grid-58 text-red",
    component: EditSession,
    invisible : false,
    layout: "/admin"
  },
  {
    path: "/tables",
    name: "Références",
    icon: "ni ni-bullet-list-67 text-red",
    component: Tables,
    invisible : true,
    layout: "/admin"
  },
  {
    path: "/vod",
    name: "Videos",
    icon: "ni ni-tv-2 text-red",
    component: Vod,
    invisible : true,
    layout: "/admin"
  },
  {
    path: "/ajouter-vod",
    name: "Ajout VOD",
    icon: "ni ni-calendar-grid-58 text-red",
    component: AddVod,
    invisible : false,
    layout: "/admin"
  },
  {
    path: "/elus",
    name: "Clients",
    icon: "ni ni-single-02 text-red",
    component: Clients,
    invisible : true,
    layout: "/admin"
  },
  {
    path: "/formateurs",
    name: "Formateurs",
    icon: "ni ni-hat-3 text-red",
    component: Formateurs,
    invisible : true,
    layout: "/admin"
  },
  {
    path: "/commandes",
    name: "Commandes",
    icon: "ni ni-cart text-red",
    component: Commandes,
    invisible : true,
    layout: "/admin"
  },
  {
    path: "/produits",
    name: "VOD à l'unité",
    icon: "ni ni-tv-2 text-red",
    component: Produits,
    invisible : true,
    layout: "/admin"
  },
  {
    path: "/remises",
    name: "Codes promos",
    icon: "fa-solid fa-percent text-red",
    component: Remise,
    invisible : true,
    layout: "/admin"
  },
  {
    path: "/add-code",
    name: "Ajouter remise",
    icon: "ni ni-hat-3 text-red",
    component: AddRemise,
    invisible : false,
    layout: "/admin"
  },
  {
    path: "/ajouter-produit",
    name: "Ajouter produit",
    icon: "ni ni-hat-3 text-red",
    component: AddProduit,
    invisible : false,
    layout: "/admin"
  },
  {
    path: "/ajouter-formateur",
    name: "Formateurs",
    icon: "ni ni-hat-3 text-red",
    component: FormateursAdd,
    invisible : false,
    layout: "/admin"
  },
  {
    path: "/ajouter-client",
    name: "Client add",
    icon: "ni ni-hat-3 text-red",
    component: ClientsAdd,
    invisible : false,
    layout: "/admin"
  },
  
  {
    path: "/login",
    name: "Login",
    icon: "ni ni-key-25 text-info",
    component: Login,
    layout: "/auth"
  },
  // {
  //   path: "/register",
  //   name: "Register",
  //   icon: "ni ni-circle-08 text-pink",
  //   component: Register,
  //   layout: "/auth"
  // }
];
export default routes;

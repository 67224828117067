/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// reactstrap components
import {
  Card,
  CardHeader,
  Container,
  Row,
  Table,
  Media,
  Modal, ModalHeader, ModalBody, ModalFooter,
  Button
} from "reactstrap";
import moment from "moment";
// core components
import Header from "components/Headers/UserHeader";
import { Link } from "react-router-dom";
import { API_ISE } from "../../variables/config"

class Icons extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      activeNav: 1,
      chartExample1Data: "data1",
      cat : "",
      formations : "",
      references : "",
      Last : "",
      formData : [],
      Details : [],
      modal: false,
      catData : [],
      formateurs : [],
    };
    this.toggle = this.toggle.bind(this);

  }
  deleteForm =(e)=>{
    console.log(e)
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ 
        FormateurID : e._id
     })
  };
  
  fetch(API_ISE+"/clients/delete/", requestOptions)
      .then(response => response.json())
      .then(data => alert("Formateur supprimé"));
  }
  toggle(item) {
    this.setState({
      modal: !this.state.modal,
      Details : item.Count,
      Last : item.LastLogin
    });
  }
  toggleClose() {
    this.setState({
      modal: !this.state.modal
    });
  }
  HandleDif(ItemID, Dif) {
    console.log(ItemID, Dif)
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ 
        FormID : ItemID,
        Dif : !Dif
     })
  };
  fetch(API_ISE+"/formations/updateDif/", requestOptions)
      .then(response => response.json())
      .then(data => this.getData());
  }
  HandleUne(ItemID, Une) {
    console.log(ItemID, Une)
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ 
        FormID : ItemID,
        Une : !Une
     })
  };
  fetch(API_ISE+"/formations/updateUne/", requestOptions)
      .then(response => response.json())
      .then(data => this.getData());
  }

  async getData(){
    await fetch(API_ISE + "/clients/")
      .then((res) => res.json())
      .then((result) => {
        console.log(result);
        this.setState({
          formateurs : result
        });
      });
  }
  componentDidMount(){
  
 this.getData()
 
  }

  render() {
    const { formData, formateurs } = this.state;
    return (
      <>
        <Header
          form={this.state.formations}
          cat={this.state.cat}
          reference={this.state.references}
        />
        {/* Page content */}
        <Container className=" mt--7" fluid>
        
          <Row>
            <div className=" col">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-0">
                        Clients - Elus | Institut Supérieur des Élus
                      </h3>
                    </div>
                    {/* <div className="col text-right">
                      <Link
                        className="btn-success btn btn-sm"
                        to="/admin/ajouter-client"
                      >
                        Ajouter un client
                      </Link>
                    </div> */}
                  </Row>
                </CardHeader>
                <Modal
                        isOpen={this.state.modal}
                        toggle={()=>this.toggleClose()}
                        className={this.props.className}
                      >
                        <ModalHeader toggle={()=>this.toggleClose()}>
                          Détails des connexions
                        </ModalHeader>
                        <ModalBody>
                          <div className="d-flex justify-content-between">
                            <h3>Dernière connexion :</h3>
                            <p className=" font-weight-bold text-danger">{moment(this.state.Last).format('lll')}</p>
                          </div>
                          <div >
                            <h3>Temps passé :</h3>
                            {this.state.Details.map((data,b)=>{
                              var time = "min"
                              var a = moment(data.start);
                              var c = moment(data.end);
                              var compte = c.diff(a, 'minutes')
                              if (compte === 0) {
                                compte = 1
                                
                              }
                              if (compte > 60) {
                                compte = (compte / 60).toFixed()

                                time = "heures"
                              }
                              return(
                                <ul key={b+10}>
                                <li >Date : {moment(data.start).format('lll')}
                                    <ul>
                                        <li className="font-weight-bold">Durée : {compte + ' ' + time}</li>
                                    </ul>
                                </li>
                            </ul>
                              )
                            })}
                          </div>
                        </ModalBody>
                        <ModalFooter>
                          <Button color="secondary" onClick={()=>this.toggleClose()}>
                            Fermer
                          </Button>
                        </ModalFooter>
                      </Modal>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Type</th>
                      <th scope="col">Nom/Prénom</th>
                      <th scope="col">Département</th>
                      <th scope="col">Commune</th>
                      <th scope="col">Fonction</th>
                      <th scope="col">Mairie</th>
                      <th scope="col">Inter</th>
                      <th scope="col">Déparement</th>
                      <th scope="col">Région</th>
                      <th scope="col">Détails</th>
                      <th scope="col">Supprimer</th>
                    </tr>
                  </thead>
                  <tbody>
                    {formateurs.map((item, i) => {
                      return (
                        <>
                        <tr key={i}>
                          
                          <td>{item.type}</td>
                          <td>{item.Nom + " " + item.Prenom}</td>
                          <td>{item.Dep}</td>
                          <td>{item.Commune}</td>
                          <td>{item.Fonction}</td>
                          <td>
                            <div className="custom-control custom-control-alternative custom-checkbox">
                              <input
                                className="custom-control-input border-dark"
                                id={item._id}
                                type="checkbox"
                                checked={item.Mairie ? true : false}
                                onChange={() =>
                                  this.HandleDif(item._id, item.Mairie)
                                }
                              />
                              <label
                                className="custom-control-label mb-2"
                                htmlFor={item._id}
                              >
                                <span className="text-muted"></span>
                              </label>
                            </div>
                          </td>
                          <td>
                            <div className="custom-control custom-control-alternative custom-checkbox">
                              <input
                                className="custom-control-input border-dark"
                                id={item._id}
                                type="checkbox"
                                checked={item.Inter ? true : false}
                                onChange={() =>
                                  this.HandleDif(item._id, item.Inter)
                                }
                              />
                              <label
                                className="custom-control-label mb-2"
                                htmlFor={item._id}
                              >
                                <span className="text-muted"></span>
                              </label>
                            </div>
                          </td>
                          <td>
                            <div className="custom-control custom-control-alternative custom-checkbox">
                              <input
                                className="custom-control-input border-dark"
                                id={item._id}
                                type="checkbox"
                                checked={item.Departement ? true : false}
                                onChange={() =>
                                  this.HandleDif(item._id, item.Departement)
                                }
                              />
                              <label
                                className="custom-control-label mb-2"
                                htmlFor={item._id}
                              >
                                <span className="text-muted"></span>
                              </label>
                            </div>
                          </td>
                          <td>
                            <div className="custom-control custom-control-alternative custom-checkbox">
                              <input
                                className="custom-control-input border-dark"
                                id={i}
                                type="checkbox"
                                checked={item.Region ? true : false}
                                onChange={() =>
                                  this.HandleUne(item._id, item.Region)
                                }
                              />
                              <label
                                className="custom-control-label mb-2"
                                htmlFor={i}
                              >
                                <span className="text-muted"></span>
                              </label>
                            </div>
                          </td>
                          <td>
                            <Button
                              className="btn-primary btn btn-sm"
                              onClick={() => this.toggle(item)}
                            >
                              Détails
                            </Button>
                          </td>
                          <td>
                            <Button
                              className="btn-danger btn btn-sm"
                              onClick={() => this.deleteForm(item)}
                            >
                              Supprimer
                            </Button>
                          </td>
                        </tr>
                        </>
                      );
                    })}
                  </tbody>
                </Table>
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

export default Icons;


import React from "react";
// react component that copies the given text inside your clipboard
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Button
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import { Link } from "react-router-dom";
import { API_ISE } from "../../variables/config"
import Label from "reactstrap/lib/Label";
import moment from "moment";
import Dropzone from "dropzone";
class EditCat extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      CatName : "",
      Image : "",
      LinkCatalogue : ""
    };
    this.dataEdit = this.dataEdit.bind(this);
    
  }
  componentDidMount() {
    
   
 }
  dataEdit=()=>{
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ CatName :this.state.CatName,
        Image : this.state.Image,
        Catalogue : this.state.LinkCatalogue,
        Content : this.state.CatName,
       })
    };
    fetch(API_ISE+"/formations/add-cat/", requestOptions)
        .then(response => response.json())
        .then(data => this.props.history.push('/admin/categories'));
  }

  render() {
    console.log(this.state)
    return (
      <>
        <Header
          form={this.state.formations}
          cat={this.state.cat}
          reference={this.state.references}
        />
        {/* Page content */}
        <Container className=" mt--7" fluid>
          {/* Table */}
          <Row>
            <div className=" col">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-0">Nouvelle catégorie</h3>
                    </div>
                    <div className="col text-right">
                      <Link
                        className="btn-success btn btn-sm"
                        to="/admin/formations"
                      >
                        Enregistrer
                      </Link>
                    </div>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Form role="form">
                    <Row>
                      <FormGroup className="col-lg-4">
                        <Label for="TitleLabel">Nom de la catégorie</Label>
                        <Input
                          type="text"
                          name="TitleLabel"
                          placeholder="Catégorie"
                          id="TitleLabel"
                          defaultValue={this.state.CatName}
                          onChange={(e)=>this.setState({CatName : e.target.value})}
                        />
                      </FormGroup>
                      <FormGroup className="col-lg-4">
                        <Label for="SubTitleLabel">Picto</Label>
                        <Input
                          type="text"
                          name="SubTitleLabel"
                          placeholder="Logo"
                          id="SubTitleLabel"
                          defaultValue={this.state.Image}
                          onChange={(e)=>this.setState({Image : e.target.value})}
                        />
                      </FormGroup>
                      <FormGroup className="col-lg-4">
                        <Label for="SubTitleLabel">Lien catalogue</Label>
                        <Input
                          type="text"
                          name="SubTitleLabel"
                          placeholder="Lien catalogue"
                          id="SubTitleLabel"
                          defaultValue={this.state.LinkCatalogue}
                          onChange={(e)=>this.setState({LinkCatalogue : e.target.value})}
                        />
                      </FormGroup>
                      <div className="fallback">
                          <div className="custom-file">
                            <input
                              className="custom-file-input"
                              id="customFileUploadMultiple"
                              multiple="multiple"
                              type="file"
                            />
                            <label
                              className="custom-file-label"
                              htmlFor="customFileUploadMultiple"
                            >
                              Télécharger
                            </label>
                          </div>
                        </div>
                    </Row>
                    <div className="text-center">
                      <Button className="mt-4" color="success" type="button" onClick={()=> this.dataEdit()}>
                        Enregistrer
                      </Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

export default EditCat;

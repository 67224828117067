/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState, useEffect, useRef, useCallback} from "react";
// reactstrap components
import {
  Card,
  CardHeader,
  Container,
  Row,
  Table,
  FormGroup,
  Label,
  Input,
  Button
} from "reactstrap";
import Modal from 'react-bootstrap/Modal'
// core components
import Header from "components/Headers/Header.js";
import ReactStars from 'react-stars'
import { API_ISE } from "../../variables/config"

export function AvisDATA({location}){
  const [Name, setName] = useState("")
  const [Mandat, setMandat] = useState("")
  const [Content, setContent] = useState("")
  const [Stars, setStars] = useState(0)
  const [show, setShow] = useState(false)
  const [Name2, setName2] = useState("")
  const [Mandat2, setMandat2] = useState("")
  const [Content2, setContent2] = useState("")
  const [Stars2, setStars2] = useState(0)
  const [idForm, setID] = useState("")
  const [show2, setShow2] = useState(false)
  const [formData, setFormData] = useState(null)
  const mountedRef = useRef(true)
 const handleShow = () => {
    setShow(true)
  }
 const handleClose = () => {
    setShow(false)
  }
 const handleClose2 = () => {
    setShow2(false)
  }
 const ModifAvis =(e)=>{
    setShow2(true)
    setName2(e.Name)
    setMandat2(e.Mandat)
    setStars2(e.Stars)
    setContent2(e.Content)
    setID(e._id)
  }
 const ModifAvisPu =(e)=>{
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ 
        Name: Name2,
        Content: Content2,
        Mandat : Mandat2,
        Stars: Stars2,
        AvisID: idForm,
        FormID : location.state.data._id
     })
  };
  fetch(API_ISE+"/formations/modif-avis/", requestOptions)
      .then(response => response.json())
      .then(data =>{handleClose2(); DataAvis()});
  }
 const deleteAvis =(e)=>{
    console.log(e)
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ 
        AvisID: e._id,
        FormID : location.state.data._id
     })
  };
  fetch(API_ISE+"/formations/delete-avis/", requestOptions)
      .then(response => response.json())
      .then(data => DataAvis());
  }
 const dataEdit=()=>{
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ 
          Name: Name,
          Content: Content,
          Mandat : Mandat,
          Stars: Stars,
          FormID : location.state.data._id
       })
    };
    fetch(API_ISE + "/formations/avis/", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setShow(false);
        DataAvis()
      });
  }
  const DataAvis = useCallback(() =>{
    fetch(API_ISE + "/formations/avisID?FormID="+location.state.data._id)
      .then((response) => response.json())
      .then((data) => {
        setFormData(data.avis)
      });
  },[location])

  useEffect(()=>{
    DataAvis()
  },[DataAvis])

 
 
  return (
    <>
        <Header
        />
        {/* Page content */}
        <Container className=" mt--7" fluid>
          {/* Table */}
          <Row>
            <div className=" col">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-0">Formations | Avis & Notes</h3>
                    </div>
                    <div className="col text-right">
                      <Button
                        className="btn-success btn btn-sm"
                        onClick={()=>handleShow()}
                      >
                        Ajouter un avis
                      </Button>
                    </div>
                  </Row>
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Nom</th>
                      <th scope="col">Avis</th>
                      <th scope="col">Note</th>
                      <th scope="col">modifier</th>
                      <th scope="col">Supprimer</th>
                    </tr>
                  </thead>
                  <tbody>
                    {formData?.Commentaires.map((item, i) => {
                      return (
                        <tr key={i}>
                          <td>{item.Name}</td>
                          <th scope="row">{item.Content}</th>

                          <td className="font-weight-bold">
                            {item.Stars}/10{" "}
                            <ReactStars
                              count={5}
                              edit={false}
                              value={item.Stars}
                              size={24}
                              color2={"#ffd700"}
                            />
                          </td>
                          <td>
                            <Button className="btn-info btn btn-sm" onClick={()=> ModifAvis(item)}>
                              Modifier
                            </Button>
                          </td>
                          <td>
                            <Button className="btn-danger btn btn-sm" onClick={()=> deleteAvis(item)}>
                              Supprimer
                            </Button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </Card>
            </div>
            <Modal show={show} onHide={()=> handleClose()}>
              <Modal.Header closeButton>
                <Modal.Title>Ajouter un avis</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <FormGroup >
                  <Label for="TitleLabel">Nom/Prénom</Label>
                  <Input
                    type="text"
                    name="Nom"
                    placeholder="Nom/Prénom"
                    id="Nom"
                    onChange={(e) => setName(e.target.value)}
                  />
                </FormGroup>
                <FormGroup >
                  <Label for="TitleLabel">Mandat de l’élu</Label>
                  <Input
                    type="text"
                    name="Mandat"
                    placeholder="Mandat"
                    id="Mandat"
                    onChange={(e) => setMandat(e.target.value)}
                  />
                </FormGroup>
                <FormGroup >
                  <Label for="TitleLabel">Note /5</Label>
                  <Input
                    type="number"
                    name="Stars"
                    placeholder="Note /5"
                    id="Note"
                    onChange={(e) => setStars(e.target.value )}
                  />
                </FormGroup>
                
                <FormGroup >
                  <Label for="TitleLabel">Commentaire</Label>
                  <Input
                    type="textarea"
                    name="Contenu"
                    id="Nom"
                    onChange={(e) => setContent(e.target.value )}
                  />
                </FormGroup>
              </Modal.Body>
              <Modal.Footer>
                <Button className="btn-danger btn btn-sm" onClick={()=> handleClose()}>
                  Fermer
                </Button>
                <Button className="btn-success btn btn-sm" onClick={()=> dataEdit()}>
                  Enregistrer
                </Button>
              </Modal.Footer>
            </Modal>
            <Modal show={show2} onHide={()=> handleClose2()}>
              <Modal.Header closeButton>
                <Modal.Title>Ajouter un avis</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <FormGroup >
                  <Label for="TitleLabel">Nom/Prénom</Label>
                  <Input
                    type="text"
                    name="Nom"
                    placeholder="Nom/Prénom"
                    id="Nom"
                    value={Name2}
                    onChange={(e) => setName2(e.target.value)}
                  />
                </FormGroup>
                <FormGroup >
                  <Label for="TitleLabel">Mandat de l’élu</Label>
                  <Input
                    type="text"
                    name="Mandat"
                    placeholder="Mandat"
                    id="Mandat"
                    value={Mandat2}
                    onChange={(e) => setMandat2(e.target.value)}
                  />
                </FormGroup>
                <FormGroup >
                  <Label for="TitleLabel">Note /5</Label>
                  <Input
                    type="number"
                    name="Stars"
                    placeholder="Note /5"
                    id="Note"
                    value={Stars2}
                    onChange={(e) => setStars2(e.target.value )}
                  />
                </FormGroup>
                
                <FormGroup >
                  <Label for="TitleLabel">Commentaire</Label>
                  <Input
                    type="textarea"
                    name="Contenu"
                    id="Nom"
                    value={Content2}
                    onChange={(e) => setContent2(e.target.value )}
                  />
                </FormGroup>
              </Modal.Body>
              <Modal.Footer>
                <Button className="btn-danger btn btn-sm" onClick={()=> handleClose2()}>
                  Fermer
                </Button>
                <Button className="btn-success btn btn-sm" onClick={()=> ModifAvisPu()}>
                  Enregistrer
                </Button>
              </Modal.Footer>
            </Modal>
          </Row>
        </Container>
      </>
  )
}

// class Avis extends React.Component {
//   constructor(props){
//     super(props);
//     this.state = {
//       activeNav: 1,
//       Name : "",
//       Content : "",
//       Stars : 0,
//       show : false,
//       chartExample1Data: "data1",
//       cat : "",
//       formations : "",
//       references : "",
//       Mandat : "",
//       formData : [],
      
//       catData : []
//     };
//   }
 
//   render() {
//     const { show, formData } = this.state;
//     const { data } = this.props.location.state;
//     return (
//       <>
//         <Header
//           form={this.state.formations}
//           cat={this.state.cat}
//           reference={this.state.references}
//         />
//         {/* Page content */}
//         <Container className=" mt--7" fluid>
//           {/* Table */}
//           <Row>
//             <div className=" col">
//               <Card className="shadow">
//                 <CardHeader className="border-0">
//                   <Row className="align-items-center">
//                     <div className="col">
//                       <h3 className="mb-0">Formations | Avis & Notes</h3>
//                     </div>
//                     <div className="col text-right">
//                       <Button
//                         className="btn-success btn btn-sm"
//                         onClick={()=>this.handleShow()}
//                       >
//                         Ajouter un avis
//                       </Button>
//                     </div>
//                   </Row>
//                 </CardHeader>
//                 <Table className="align-items-center table-flush" responsive>
//                   <thead className="thead-light">
//                     <tr>
//                       <th scope="col">Nom</th>
//                       <th scope="col">Avis</th>
//                       <th scope="col">Note</th>
//                       <th scope="col">Supprimer</th>
//                     </tr>
//                   </thead>
//                   <tbody>
//                     {formData?.Commentaires.map((item, i) => {
//                       return (
//                         <tr key={i}>
//                           <td>{item.Name}</td>
//                           <th scope="row">{item.Content}</th>

//                           <td className="font-weight-bold">
//                             {item.Stars}/10{" "}
//                             <ReactStars
//                               count={5}
//                               edit={false}
//                               value={item.Stars}
//                               size={24}
//                               color2={"#ffd700"}
//                             />
//                           </td>
//                           <td>
//                             <Button className="btn-danger btn btn-sm" onClick={()=>this.deleteAvis(item)}>
//                               Supprimer
//                             </Button>
//                           </td>
//                         </tr>
//                       );
//                     })}
//                   </tbody>
//                 </Table>
//               </Card>
//             </div>
//             <Modal show={show} onHide={()=>this.handleClose()}>
//               <Modal.Header closeButton>
//                 <Modal.Title>Ajouter un avis</Modal.Title>
//               </Modal.Header>
//               <Modal.Body>
//                 <FormGroup >
//                   <Label for="TitleLabel">Nom/Prénom</Label>
//                   <Input
//                     type="text"
//                     name="Nom"
//                     placeholder="Nom/Prénom"
//                     id="Nom"
//                     onChange={(e) => this.setState({ Name: e.target.value })}
//                   />
//                 </FormGroup>
//                 <FormGroup >
//                   <Label for="TitleLabel">Mandat de l’élu</Label>
//                   <Input
//                     type="text"
//                     name="Mandat"
//                     placeholder="Mandat"
//                     id="Mandat"
//                     onChange={(e) => this.setState({ Mandat: e.target.value })}
//                   />
//                 </FormGroup>
//                 <FormGroup >
//                   <Label for="TitleLabel">Note /5</Label>
//                   <Input
//                     type="number"
//                     name="Stars"
//                     placeholder="Note /5"
//                     id="Note"
//                     onChange={(e) => this.setState({ Stars: e.target.value })}
//                   />
//                 </FormGroup>
                
//                 <FormGroup >
//                   <Label for="TitleLabel">Commentaire</Label>
//                   <Input
//                     type="textarea"
//                     name="Contenu"
//                     id="Nom"
//                     onChange={(e) => this.setState({ Content: e.target.value })}
//                   />
//                 </FormGroup>
//               </Modal.Body>
//               <Modal.Footer>
//                 <Button className="btn-danger btn btn-sm" onClick={()=>this.handleClose()}>
//                   Fermer
//                 </Button>
//                 <Button className="btn-success btn btn-sm" onClick={()=>this.dataEdit()}>
//                   Enregistrer
//                 </Button>
//               </Modal.Footer>
//             </Modal>
//           </Row>
//         </Container>
//       </>
//     );
//   }
// }

export default AvisDATA;

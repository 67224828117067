import React from "react";
// react component that copies the given text inside your clipboard
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Input,
  Container,
  Row,
  Button,
} from "reactstrap";
import Select from "react-select";
// core components
import Header from "components/Headers/UserHeader";
import { API_ISE } from "../../variables/config";
import Label from "reactstrap/lib/Label";
import Dropzone from "dropzone";

class AddSession extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Title: "",
      Description: "",
      Status: true,
      Ecole: "",
      Formateur: "",
      Categorie: "",
      res: [],
      cat: [],
      Regions: "",
      Fiche: "",
      Places: 0,
      Ville: "",
      Mots: "",
      Ref: "",
      Link: "",
      load: false,
      err: "",
      photoUrl: "",
    };
    this.dataEdit = this.dataEdit.bind(this);
  }

  async componentDidMount() {
    let currentSingleFile = undefined;
    Dropzone.autoDiscover = false;
    // single dropzone file - accepts only images
    new Dropzone(document.getElementById("dropzone-single"), {
      url:  "https://api.formations-des-elus.fr/files/upload/",
      thumbnailWidth: null,
      thumbnailHeight: null,
      dictDefaultMessage: "Télécharger une photo de profile",
      previewsContainer: document.getElementsByClassName(
        "dz-preview-single"
      )[0],
      previewTemplate: document.getElementsByClassName("dz-preview-single")[0]
        .innerHTML,
      maxFiles: 1,
      acceptedFiles: "image/*",
      success: (file, response) => {
        console.log(response.result.secure_url );
        this.setState({ photoUrl: response.result.secure_url });
      },
      init: function () {
        this.on("addedfile", (file) => {
          if (currentSingleFile) {
            this.removeFile(currentSingleFile);
          }
          currentSingleFile = file;
        });
      }, 
    });
    document.getElementsByClassName("dz-preview-single")[0].innerHTML = "";

    await fetch(API_ISE + "/formateurs/")
      .then((res) => res.json())
      .then((result) => {
        console.log(result);
        var res = [];
        for (let i = 0; i < result.length; i++) {
          const element = result[i];
          res.push({
            label: element.Nom + " - " + element.Prenom,
            value: element._id,
          });
        }
        this.setState({
          res,
        });
      });
    await fetch(API_ISE + "/formations/cats")
      .then((res) => res.json())
      .then((result) => {
        console.log(result);
        var res = [];
        for (let i = 0; i < result.data.length; i++) {
          const element = result.data[i];
          res.push({
            label: element.CatLabel,
            value: element._id,
          });
        }
        this.setState({
          cat: res,
        });
      });
  }
  handleChangeRegion = (region) => {
    this.setState({ Categorie: region.value });
  };
  handleChangeEcole = (region) => {
    this.setState({ Ecole: region.value });
  };
  handleChangeFormateur = (Formateur) => {
    this.setState({ Formateur: Formateur.value });
  };
  dataEdit = () => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        Ecole: this.state.Ecole,
        Title: this.state.Title,
        Description: this.state.Description,
        Formateur: this.state.Formateur,
        Categorie: this.state.Categorie,
        Ref: this.state.Ref,
        Link: this.state.Link,
        LinkVimeo: this.state.LinkVimeo,
        photoUrl : this.state.photoUrl
      }),
    };
    fetch(API_ISE + "/vod/new/", requestOptions)
      .then((response) => response.json())
      .then((data) => this.props.history.push("/admin/vod"));
  };

  render() {
    return (
      <>
        <Header
          form={this.state.formations}
          cat={this.state.cat}
          reference={this.state.references}
        />
        {/* Page content */}
        <Container className=" mt--7" fluid>
          {/* Table */}
          <Row>
            <div className=" col">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-0">Nouvelle vidéo</h3>
                    </div>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Row>
                    <div className="col-lg-6">
                      <FormGroup className="col-lg-12">
                        <Label for="TitleLabel">Catégorie</Label>
                        <Select
                          onChange={this.handleChangeRegion}
                          placeholder="Séléctionnez un catégorie"
                          options={this.state.cat}
                          required={true}
                        />
                      </FormGroup>
                      <FormGroup className="col-lg-12">
                        <Label for="TitleLabel">Ecole</Label>
                        <Select
                          onChange={this.handleChangeEcole}
                          placeholder="Séléctionnez une école"
                          options={acces}
                          required={true}
                        />
                      </FormGroup>
                      <FormGroup className="col-lg-12">
                        <Label for="Formation">Formateur</Label>
                        <Select
                          onChange={this.handleChangeFormateur}
                          placeholder="Séléctionnez un formateur"
                          options={this.state.res}
                          required={true}
                        />
                      </FormGroup>

                      <FormGroup className="col-lg-12">
                        <Label for="TitleLabel">Référence</Label>
                        <Input
                          type="text"
                          name="Référence"
                          placeholder="Référence"
                          id="Référence"
                          defaultValue={this.state.Ref}
                          onChange={(e) =>
                            this.setState({ Ref: e.target.value })
                          }
                        />
                      </FormGroup>
                      <FormGroup className="col-lg-12">
                      <div
                          className="dropzone dropzone-single mb-3 col-6 btn-danger"
                          id="dropzone-single"
                        >
                          <div className="fallback ">
                            <div className="custom-file btn-danger">
                              <input
                                className="custom-file-input btn-danger"
                                id="projectCoverUploads"
                                type="file"
                                name="file"
                              />
                              <label
                                className="custom-file-label"
                                htmlFor="projectCoverUploads "
                              >
                                Télécharger une vignette
                              </label>
                            </div>
                          </div>
                          <div className="dz-preview dz-preview-single">
                            <div className="dz-preview-cover">
                              <img
                                alt="..."
                                className="dz-preview-img avatar avatar-lg"
                                data-dz-thumbnail=""
                              />
                            </div>
                          </div>
                        </div>
                      </FormGroup>
                    </div>
                    <div className="col-lg-6">
                      <div className="col-lg-12">
                        <FormGroup className="col-lg-12">
                          <Label for="TitleLabel">Titre</Label>
                          <Input
                            type="text"
                            name="Titre"
                            placeholder="Titre de la vidéo"
                            id="TitleLabel"
                            defaultValue={this.state.Title}
                            onChange={(e) =>
                              this.setState({ Title: e.target.value })
                            }
                          />
                        </FormGroup>
                        <FormGroup className="col-lg-12">
                          <Label for="TitleLabel">Description</Label>
                          <Input
                            type="textarea"
                            name="Description"
                            placeholder="Description de la vidéo"
                            id="Description"
                            defaultValue={this.state.Description}
                            onChange={(e) =>
                              this.setState({ Description: e.target.value })
                            }
                          />
                        </FormGroup>
                        <FormGroup className="col-lg-12">
                          <Label for="SubTitleLabel">Lien de la vidéo</Label>
                          <Input
                            type="text"
                            name="Link"
                            placeholder="Lien de la vidéo : Youtube, Viméo autres"
                            id="Link"
                            defaultValue={this.state.Link}
                            onChange={(e) =>
                              this.setState({ Link: e.target.value })
                            }
                          />
                        </FormGroup>
                        <FormGroup className="col-lg-12">
                          <Label for="SubTitleLabel">Numéro de la vidéo</Label>
                          <Input
                            type="text"
                            name="Link"
                            placeholder="Ref de la vidéo : Youtube, Viméo autres"
                            id="Link"
                            defaultValue={this.state.LinkVimeo}
                            onChange={(e) =>
                              this.setState({ LinkVimeo: e.target.value })
                            }
                          />
                        </FormGroup>
                      </div>
                    </div>
                  </Row>
                  <div className="text-center">
                    <Button
                      className="mt-4"
                      color="success"
                      type="button"
                      onClick={() => this.dataEdit()}
                    >
                      Enregistrer
                    </Button>
                  </div>
                </CardBody>
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}
const acces = [
  {
    value: "ise",
    label: "Institut Supérieur des Élus",
  },
  {
    value: "endc",
    label: "École nationale des Directeurs de cabinet",
  },
  {
    value: "dgs",
    label: "Institut des Hautes Études de la Direction Générale des Services",
  },
  {
    value: "inap",
    label: "Institut National des Agents Publics",
  },
  {
    value: "eloc",
    label: "Institut Supérieur de l’Éloquence",
  },
  {
    value: "inter",
    label: "IntercoFormations",
  },
  {
    value: "win",
    label: "Election Win",
  },
];
export default AddSession;

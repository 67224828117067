
import React from "react";
// react component that copies the given text inside your clipboard
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Button
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import { Link } from "react-router-dom";
import { API_ISE } from "../../variables/config"
import Label from "reactstrap/lib/Label";

class EditCat extends React.Component {
  constructor(props){
    console.log(props)
    super(props);
    this.state = {
      ClientName : props.location.state.data.ClientName,
      Region : props.location.state.data.Region,
      LinkLogo : props.location.state.data.LinkLogo,
    };
    this.dataEdit = this.dataEdit.bind(this)
  }
  
  dataEdit=()=>{
    fetch(API_ISE+"/formations/edit-ref?CatID="+this.props.location.state.data._id+"&ClientName="+this.state.ClientName+"&LinkLogo="+this.state.LinkLogo+"&Region="+this.state.Region)
      .then(res => res.json())
      .then(
        (result) => {
          console.log(result)
          this.props.history.push('/admin/tables')
        },
      )
  
  }

  render() {
    console.log(this.state)
    return (
      <>
        <Header
          form={this.state.formations}
          cat={this.state.cat}
          reference={this.state.references}
        />
        {/* Page content */}
        <Container className=" mt--7" fluid>
          {/* Table */}
          <Row>
            <div className=" col">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-0">Modifier référence</h3>
                    </div>
                    <div className="col text-right">
                      <Link
                        className="btn-success btn btn-sm"
                        to="/admin/formations"
                      >
                        Enregistrer
                      </Link>
                    </div>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Form role="form">
                    <Row>
                      <FormGroup className="col-lg-4">
                        <Label for="TitleLabel">Ville</Label>
                        <Input
                          type="text"
                          name="TitleLabel"
                          placeholder="Ville"
                          id="TitleLabel"
                          defaultValue={this.props.location.state.data.ClientName}
                          onChange={(e)=>this.setState({ClientName : e.target.value})}
                        />
                      </FormGroup>
                      <FormGroup className="col-lg-4">
                        <Label for="SubTitleLabel">Région</Label>
                        <Input
                          type="text"
                          name="SubTitleLabel"
                          placeholder="Région"
                          id="SubTitleLabel"
                          defaultValue={this.props.location.state.data.Region}
                          onChange={(e)=>this.setState({Region : e.target.value})}
                        />
                      </FormGroup>
                      <FormGroup className="col-lg-4">
                        <Label for="RefLabel">Lien logo</Label>
                        <Input
                          type="text"
                          name="RefLabel"
                          placeholder="Lien logo"
                          id="RefLabel"
                          defaultValue={this.props.location.state.data.LinkLogo}
                          onChange={(e)=>this.setState({LinkLogo : e.target.value})}
                        />
                      </FormGroup>
                    </Row>
                    
                    
                    
                    <div className="text-center">
                      <Button className="mt-4" color="success" type="button" onClick={()=> this.dataEdit()}>
                        Modifier
                      </Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

export default EditCat;

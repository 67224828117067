import React from "react";
// react component that copies the given text inside your clipboard
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Button,
  Col
} from "reactstrap";
import Select from "react-select";
// core components
import Header from "components/Headers/UserHeader";
import Datetime from 'react-datetime';
import { API_ISE } from "../../variables/config";
import Label from "reactstrap/lib/Label";
import 'moment/locale/fr';
import Dropzone from "dropzone";
class AddSession extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Title: "",
      Description: "",
      Status: true,
      Formateur: "",
      Categorie: "",
      res: [],
      cat: [],
      Regions: "",
      Fiche: "",
      Places: 0,
      Amount: 0,
      Ville : "",
      Mots : "",
      Ref: "",
      Link :"",
      Match : [],
      Region : false,
        Mairie : false,
        Inter : false,
        Departement : false,
    load: false,
    err: "",
    photoUrl:''
    };
    this.dataEdit = this.dataEdit.bind(this);
  }
 
  async componentDidMount() {

    await fetch(API_ISE + "/formateurs/")
      .then((res) => res.json())
      .then((result) => {
        console.log(result);
        var res = []
        for (let i = 0; i < result.length; i++) {
          const element = result[i];
          res.push({
            label : element.Nom + ' - ' + element.Prenom ,
            value : element._id
          })
        }
         this.setState({
           res
         })
      });
      await fetch(API_ISE + "/formations/cats")
      .then((res) => res.json())
      .then((result) => {
        console.log(result);
        var res = []
        for (let i = 0; i < result.data.length; i++) {
          const element = result.data[i];
          res.push({
            label : element.CatLabel ,
            value : element._id
          })
        }
         this.setState({
           cat : res
         })
      });
  }
  handleChangeRegion = (region) => {
    console.log(region);
    this.setState({ Categorie: region.value });
  };
  handleChangeFormateur = (Formateur) => {
    console.log(Formateur);
    this.setState({ Formateur: Formateur.value });
  };
  dataEdit = () => {
    var Mairie;
    var Inter;
    var Departement;
    var Region;
    if (this.state.Mairie) {
      Mairie = "Mairie"
    }
    if (this.state.Inter) {
      Inter = "Inter"
    }
    if (this.state.Departement) {
      Departement = "Dep"
    }
    if (this.state.Region) {
      Region = "Region"
    }
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        Title: this.state.Title,
        Description: this.state.Description,
        Formateur: this.state.Formateur,
        Categorie : this.state.Categorie,
        Link: this.state.Link,
        Amount : this.state.Amount,
        Match : [Mairie, Inter, Region, Departement]
      }),
    };
    fetch(API_ISE + "/vod/new-vod/", requestOptions)
      .then((response) => response.json())
      .then((data) => this.props.history.push("/admin/produits"));
  };
 
  render() {
    console.log(this.state.Match)
    return (
      <>
        <Header
          form={this.state.formations}
          cat={this.state.cat}
          reference={this.state.references}
        />
        {/* Page content */}
        <Container className=" mt--7" fluid>
          {/* Table */}
          <Row>
            <div className=" col">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-0">Nouvelle vidéo</h3>
                    </div>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Row>
                    <div className="col-lg-6">
                      <FormGroup className="col-lg-12">
                        <Label for="TitleLabel">Catégorie</Label>
                        <Select
                          onChange={this.handleChangeRegion}
                          placeholder="Séléctionnez un catégorie"
                          options={this.state.cat}
                          required={true}
                        />
                      </FormGroup>
                      <FormGroup className="col-lg-12">
                        <Label for="Formation">
                        Formateur
                        </Label>
                        <Select
                          onChange={this.handleChangeFormateur}
                          placeholder="Séléctionnez un formateur"
                          options={this.state.res}
                          required={true}
                        />
                      </FormGroup>
                     
                      <FormGroup className="col-lg-12">
                        <Label for="TitleLabel">Prix</Label>
                        <Input
                          type="number"
                          name="Prix"
                          placeholder="Prix"
                          id="Prix"
                          defaultValue={this.state.Amount}
                          onChange={(e) =>
                            this.setState({ Amount: e.target.value })
                          }
                        />
                      </FormGroup>
                      
                    </div>
                    <div className="col-lg-6">
                    <div className="col-lg-12">
                    <FormGroup className="col-lg-12">
                        <Label for="TitleLabel">Titre</Label>
                        <Input
                          type="text"
                          name="Titre"
                          placeholder="Titre de la vidéo"
                          id="TitleLabel"
                          defaultValue={this.state.Title}
                          onChange={(e) =>
                            this.setState({ Title: e.target.value })
                          }
                        />
                      </FormGroup>
                      <FormGroup className="col-lg-12">
                        <Label for="TitleLabel">Description</Label>
                        <Input
                          type="textarea"
                          name="Description"
                          placeholder="Description de la vidéo"
                          id="Description"
                          defaultValue={this.state.Description}
                          onChange={(e) =>
                            this.setState({ Description: e.target.value })
                          }
                        />
                      </FormGroup>
                      <FormGroup className="col-lg-12">
                        <Label for="SubTitleLabel">Mot du directeur</Label>
                        <Input
                          type="text"
                          name="mots"
                          placeholder="Mot du directeur"
                          id="mots"
                          defaultValue={this.state.Link}
                          onChange={(e) =>
                            this.setState({ Mots: e.target.value })
                          }
                        />
                      </FormGroup>
                      <FormGroup className="col-lg-12">
                        <Label for="SubTitleLabel">Lien de la vidéo</Label>
                        <Input
                          type="text"
                          name="Link"
                          placeholder="Lien de la vidéo : Youtube, Viméo autres"
                          id="Link"
                          defaultValue={this.state.Link}
                          onChange={(e) =>
                            this.setState({ Link: e.target.value })
                          }
                        />
                      </FormGroup>
                    </div>
                    </div>
                  </Row>
                  <div className="text-center">
                    <Button
                      className="mt-4"
                      color="success"
                      type="button"
                      onClick={() => this.dataEdit()}
                    >
                      Enregistrer
                    </Button>
                  </div>
                </CardBody>
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}
const acces = [
 
  {
    value: "Mairie",
    label: "Mairie",
  },
  {
    value: "Inter",
    label: "Intercommunalités",
  },
  {
    value: "Region",
    label: "Région",
  },
  {
    value: "Dep",
    label: "Département",
  }
];
export default AddSession;

/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// reactstrap components
import {
  Card,
  CardHeader,
  Container,
  Row,
  Table,
  FormGroup,
  Label,
  Button,
  Input
} from "reactstrap";
import Modal from 'react-bootstrap/Modal'
import Select from 'react-select';

import Header from "components/Headers/Header.js";
import { Link } from "react-router-dom";
import { API_ISE } from "../../variables/config"

class Icons extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      activeNav: 1,
      chartExample1Data: "data1",
      cat : "",
      show : false,
      formations : "",
      references : "",
      FicheID : "",
      formData : [],
      Suggestions : null,
      catData : []
    };
  }
  Suggestions = e => { 
    console.log(e);
    this.setState({Suggestions : e})
   
  }
  handleShow = (e) => {
    console.log(e)
    this.setState({show : true, FicheID : e})
  }
  handleClose = () => {
    console.log('model')
    this.setState({show : false, FicheID  :"", uggestions : null})
  }
  PostSuggestions =(e)=>{
    const optionSeugg = [];
    for (let i = 0; i < this.state.Suggestions.length; i++) {
      const element = this.state.Suggestions[i];
      optionSeugg.push(element.value)
    }
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({Suggestions : optionSeugg, FicheID : this.state.FicheID})
  };
  fetch(API_ISE+"/formations/suggestions/", requestOptions)
      .then(response => response.json())
      .then(data => this.setState({show : false}));
  }
  deleteForm =(e)=>{
    console.log(e)
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ 
        FormID : e._id
     })
  };
  fetch(API_ISE+"/formations/delete-formation/", requestOptions)
      .then(response => response.json())
      .then(data => alert("Fiche supprimé"));
  }

  HandleDif(ItemID, Dif) {
    console.log(ItemID, Dif)
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ 
        FormID : ItemID,
        Dif : !Dif
     })
  };
  fetch(API_ISE+"/formations/updateDif/", requestOptions)
      .then(response => response.json())
      .then(data => this.getData());
  }
  HandleUne(ItemID, Une) {
    console.log(ItemID, Une)
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ 
        FormID : ItemID,
        Une : !Une
     })
  };
  fetch(API_ISE+"/formations/updateUne/", requestOptions)
      .then(response => response.json())
      .then(data => this.getData());
  }

  async getData(){
    await fetch(API_ISE + "/formations/all-stats")
      .then((res) => res.json())
      .then((result) => {
        console.log(result);
        this.setState({
          cat: result.c,
          formations: result.f,
          references: result.r,
          formData: result.formData,
          catData: result.categorie,
        });
      });
  }
  componentDidMount(){
  
 this.getData()
 
  }

  render() {
    const { formData, show , Suggestions} = this.state;
    const optionSeugg = [];
    for (let i = 0; i < formData.length; i++) {
      const element = formData[i];
      optionSeugg.push({
        value : element._id,
        label : element.TitleLabel
      })
    }
    console.log(Suggestions);
    return (
      <>
        <Header
          form={this.state.formations}
          cat={this.state.cat}
          reference={this.state.references}
        />
        {/* Page content */}
        <Container className=" mt--7" fluid>
          <Modal show={show} onHide={this.handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Ajouter un avis</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <FormGroup>
                <Label for="TitleLabel">Séléctionnez les formations à suggérer</Label>
                <Select
                  isMulti
                  name="colors"
                  onChange={this.Suggestions}
                  options={optionSeugg}
                  className="basic-multi-select"
                  classNamePrefix="select"
                />
              </FormGroup>
            </Modal.Body>
            <Modal.Footer>
              <Button
                className="btn-danger btn btn-sm"
                onClick={this.handleClose}
              >
                Fermer
              </Button>
              <Button
                className="btn-success btn btn-sm"
                onClick={this.PostSuggestions}
              >
                Enregistrer
              </Button>
            </Modal.Footer>
          </Modal>
          <Row>
            <div className=" col">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-0">
                        Formations | Institut Supérieur des Élus
                      </h3>
                    </div>
                    <div className="col text-right">
                      <Link
                        className="btn-success btn btn-sm"
                        to="/admin/ajouter-formation"
                      >
                        Ajouter une fiche
                      </Link>
                    </div>
                  </Row>
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Référence</th>
                      <th scope="col">Titre</th>
                      <th scope="col">Durée</th>
                      <th scope="col">Avis</th>
                      <th scope="col">Suggestions</th>
                      <th scope="col">HORS DIF</th>
                      <th scope="col">A LA UNE</th>
                      <th scope="col">Modifier</th>
                      <th scope="col">Supprimer</th>
                    </tr>
                  </thead>
                  <tbody>
                    {formData.map((item, i) => {
                      return (
                        <tr key={i}>
                          <td>{item.RefLabel}</td>
                          <th scope="row">{item.TitleLabel}</th>
                          <td>{item.Duree}</td>
                          <td>
                            <Link
                              className="bg-yellow btn btn-sm"
                              to={{
                                pathname: "/admin/avis",
                                state: {
                                  data: item,
                                },
                              }}
                            >
                              Avis/Note
                            </Link>
                          </td>
                          <td>
                            <Button
                              className="bg-green text-white btn btn-sm"
                              onClick={()=>this.handleShow(item._id)}
                            >
                              Associer
                            </Button>
                          </td>

                          <td>
                            <div className="custom-control custom-control-alternative custom-checkbox">
                              <input
                                className="custom-control-input border-dark"
                                id={item._id}
                                type="checkbox"
                                checked={item.Dif ? true : false}
                                onChange={() =>
                                  this.HandleDif(item._id, item.Dif)
                                }
                              />
                              <label
                                className="custom-control-label"
                                htmlFor={item._id}
                              >
                                <span className="text-muted">HORS DIF</span>
                              </label>
                            </div>
                          </td>
                          <td>
                            <div className="custom-control custom-control-alternative custom-checkbox">
                              <input
                                className="custom-control-input border-dark"
                                id={i}
                                type="checkbox"
                                checked={item.Alaune ? true : false}
                                onChange={() =>
                                  this.HandleUne(item._id, item.Alaune)
                                }
                              />
                              <label
                                className="custom-control-label"
                                htmlFor={i}
                              >
                                <span className="text-muted">A LA UNE</span>
                              </label>
                            </div>
                          </td>
                          <td>
                            <Link
                              className="btn-info btn btn-sm"
                              to={{
                                pathname: "/admin/modifier-formation",
                                state: {
                                  data: item,
                                },
                              }}
                            >
                              Modifier
                            </Link>
                          </td>
                          <td>
                            <Button
                              className="btn-danger btn btn-sm"
                              onClick={() => this.deleteForm(item)}
                            >
                              Supprimer
                            </Button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

export default Icons;

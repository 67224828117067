/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// reactstrap components
import {
  Card,
  CardHeader,
  Container,
  Row,
  Table,
  Button
} from "reactstrap";
// core components
import Header from "components/Headers/UserHeader";
import { Link } from "react-router-dom";
import { API_ISE } from "../../variables/config"

class Icons extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      activeNav: 1,
      chartExample1Data: "data1",
      cat : "",
      formations : "",
      references : "",
      formData : [],
      vod : []
    };
  }
  deleteForm =(e)=>{
    console.log(e)
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ 
        VodID : e._id
     })
  };
  fetch(API_ISE+"/vod/delete/", requestOptions)
      .then(response => response.json())
      .then(data => alert("Vidéo supprimé"));
  }

  HandleDif(ItemID, Dif) {
    console.log(ItemID, Dif)
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ 
        FormID : ItemID,
        Dif : !Dif
     })
  };
  fetch(API_ISE+"/formations/updateDif/", requestOptions)
      .then(response => response.json())
      .then(data => this.getData());
  }
  HandleUne(ItemID, Une) {
    console.log(ItemID, Une)
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ 
        FormID : ItemID,
        Une : !Une
     })
  };
  fetch(API_ISE+"/formations/updateUne/", requestOptions)
      .then(response => response.json())
      .then(data => this.getData());
  }

  async getData(){
    await fetch(API_ISE + "/vod/products")
      .then((res) => res.json())
      .then((result) => {
        console.log(result);
        this.setState({
          vod : result
        });
      });
  }
  componentDidMount(){
  
 this.getData()
 
  }

  render() {
    const { vod } = this.state;
    return (
      <>
        <Header
          form={this.state.formations}
          cat={this.state.cat}
          reference={this.state.references}
        />
        {/* Page content */}
        <Container className=" mt--7" fluid>
          {/* Table */}
          <Row>
            <div className=" col">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-0">
                        Vidéos | Institut Supérieur des Élus
                      </h3>
                    </div>
                    <div className="col text-right">
                      <Link
                        className="btn-success btn btn-sm"
                        to="/admin/ajouter-produit"
                      >
                        Ajouter une vidéo
                      </Link>
                    </div>
                  </Row>
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Titre</th>
                      <th scope="col">Catégorie</th>
                      <th scope="col">Formateur</th>
                      <th scope="col">Prix</th>
                      <th scope="col">Lien</th>
                      <th scope="col">Supprimer</th>
                    </tr>
                  </thead>
                  <tbody>
                    {vod.map((item, i) => {
                      return (
                        <tr key={i}>
                          <td>{item.Title}</td>
                          <td>{item.Categorie.CatLabel}</td>
                          <td>{item.Formateur.Nom + " " + item.Formateur.Prenom }</td>
                          <td className="font-weight-bold">{item.Amount.toFixed(2)} €</td>
                          <td>
                            <a
                              className="btn-warning btn btn-sm"
                              href={item.Link}
                              
                            >
                              Voir la vidéo
                            </a>
                          </td>
                          <td>
                            <Button
                              className="btn-danger btn btn-sm"
                              onClick={() => this.deleteForm(item)}
                            >
                              Supprimer
                            </Button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

export default Icons;

import React from "react";
// react component that copies the given text inside your clipboard
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Button,
} from "reactstrap";
import Select from "react-select";
// core components
import Header from "components/Headers/Header.js";
import Datetime from 'react-datetime';
import { API_ISE } from "../../variables/config";
import Label from "reactstrap/lib/Label";
import 'moment/locale/fr';
import Dropzone from "dropzone";
class AddSession extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Dates: "",
      Status: true,
      Formation: "",
      res: [],
      Regions: "",
      Fiche: "",
      Places: 0,
      Ville : "",
      file: "",
    load: false,
    err: "",
    photoUrl:''
    };
    this.dataEdit = this.dataEdit.bind(this);
  }
 
  async componentDidMount() {

    await fetch(API_ISE + "/formations/all-stats")
      .then((res) => res.json())
      .then((result) => {
        console.log(result);
        var res = []
        for (let i = 0; i < result.formData.length; i++) {
          const element = result.formData[i];
          res.push({
            label : element.RefLabel + ' - ' + element.TitleLabel ,
            value : element._id
          })
        }
        console.log(res)
         this.setState({
           res
         })
      });
      let currentSingleFile = undefined;
    Dropzone.autoDiscover = false;
    // single dropzone file - accepts only images
    new Dropzone(document.getElementById("dropzone-single"), {
      url:  "https://api.formations-des-elus.fr/files/upload/",
      thumbnailWidth: null,
      thumbnailHeight: null,
      dictDefaultMessage: "Télécharger une image de la ville",
      previewsContainer: document.getElementsByClassName(
        "dz-preview-single"
      )[0],
      previewTemplate: document.getElementsByClassName("dz-preview-single")[0]
        .innerHTML,
      maxFiles: 1,
      acceptedFiles: "image/*",
      success: (file, response) => {
        console.log(response.result.secure_url );
        this.setState({ photoUrl: response.result.secure_url });
      },
      init: function () {
        this.on("addedfile", (file) => {
          if (currentSingleFile) {
            this.removeFile(currentSingleFile);
          }
          currentSingleFile = file;
        });
      },
    });
    document.getElementsByClassName("dz-preview-single")[0].innerHTML = "";
  }
  handleChangeRegion = (region) => {
    console.log(region);
    this.setState({ Regions: region });
  };
  handleChangeFormation = (formation) => {
    console.log(formation);
    this.setState({ Fiche: formation.value });
  };
  dataEdit = () => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        Dates: this.state.Dates,
        Status: true,
        Regions: this.state.Regions.label,
        Formation: this.state.Formation,
        Places: this.state.Places,
        Fiche : this.state.Fiche,
        photoUrl : this.state.photoUrl,
        Ville : this.state.Ville
      }),
    };
    fetch(API_ISE + "/formations/add-event/", requestOptions)
      .then((response) => response.json())
      .then((data) => this.props.history.push("/admin/dates"));
  };

  render() {
    console.log("newClass -> render -> file", this.state.photoUrl);
    return (
      <>
        <Header
          form={this.state.formations}
          cat={this.state.cat}
          reference={this.state.references}
        />
        {/* Page content */}
        <Container className=" mt--7" fluid>
          {/* Table */}
          <Row>
            <div className=" col">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-0">Nouvelle session</h3>
                    </div>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Form role="form">
                    <Row>
                      <FormGroup className="col-lg-4">
                        <Label for="TitleLabel">Région</Label>
                        <Select
                          value={this.state.region}
                          onChange={this.handleChangeRegion}
                          placeholder="Séléctionnez une région"
                          options={categories}
                          required={true}
                        />
                      </FormGroup>
                      <FormGroup className="col-lg-4">
                        <Label for="Formation">
                          Séléctionnez une fiche formation
                        </Label>
                        <Select
                          onChange={this.handleChangeFormation}
                          placeholder="Séléctionnez une fiche"
                          options={this.state.res}
                          required={true}
                        />
                      </FormGroup>
                      <div className="col-lg-4">
                        <Label for="TitleLabel">Dates</Label>
                        <Datetime
                          locale="fr-fr"
                          inputProps={{
                            placeholder: "Sélectionnez une date",
                          }}
                          className="w-100"
                          timeFormat={false}
                          onChange={(e) => this.setState({ Dates: e })}
                        />
                        {/* <Input
                          type="text"
                          name="Dates"
                          placeholder="Dates"
                          id="TitleLabel"
                          defaultValue={this.state.Dates}
                          onChange={(e) =>
                            this.setState({ Dates: e.target.value })
                          }
                        /> */}
                      </div>
                      <FormGroup className="col-lg-4">
                        <Label for="TitleLabel">Places maximum</Label>
                        <Input
                          type="number"
                          name="Lieu"
                          placeholder="Nombre de places"
                          id="TitleLabel"
                          defaultValue={this.state.Lieu}
                          onChange={(e) =>
                            this.setState({ Places: e.target.value })
                          }
                        />
                      </FormGroup>
                      <FormGroup className="col-lg-4">
                        <Label for="SubTitleLabel">Description ou Titre</Label>
                        <Input
                          type="text"
                          name="SubTitleLabel"
                          placeholder="formation"
                          id="SubTitleLabel"
                          defaultValue={this.state.Formation}
                          onChange={(e) =>
                            this.setState({ Formation: e.target.value })
                          }
                        />
                      </FormGroup>
                      <FormGroup className="col-lg-4">
                        <Label for="SubTitleLabel">Ville</Label>
                        <Input
                          type="text"
                          name="SubTitleLabel"
                          placeholder="Ville"
                          id="SubTitleLabel"
                          defaultValue={this.state.Ville}
                          onChange={(e) =>
                            this.setState({ Ville: e.target.value })
                          }
                        />
                      </FormGroup>
                      <div
                          className="dropzone dropzone-single mb-3 col-6"
                          id="dropzone-single"
                        >
                          <div className="fallback">
                            <div className="custom-file">
                              <input
                                className="custom-file-input"
                                id="projectCoverUploads"
                                type="file"
                                name="file"
                              />
                              <label
                                className="custom-file-label"
                                htmlFor="projectCoverUploads"
                              >
                                Télécharger une photo de profile
                              </label>
                            </div>
                          </div>
                          <div className="dz-preview dz-preview-single">
                            <div className="dz-preview-cover">
                              <img
                                alt="..."
                                className="dz-preview-img"
                                data-dz-thumbnail=""
                              />
                            </div>
                          </div>
                        </div>
                    </Row>
                    <div className="text-center">
                      <Button
                        className="mt-4"
                        color="success"
                        type="button"
                        onClick={() => this.dataEdit()}
                      >
                        Enregistrer
                      </Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}
const categories = [
 
  {
    value: 2,
    label: "Auvergne-Rhône-Alpes",
  },
  {
    value: 3,
    label: "Bretagne",
  },
  {
    value: 1,
    label: "Bourgogne-Franche-Comté",
  },
  {
    value: 4,
    label: "Centre-Val de Loire",
  },
  {
    value: 5,
    label: "Corse",
  },
  {
    value: 6,
    label: "Grand-Est",
  },
  {
    value: 7,
    label: "Hauts-de-France",
  },
  {
    value: 8,
    label: "Ile-de-France",
  },
  {
    value: 9,
    label: "Normandie",
  },
  {
    value: 10,
    label: "Nouvelle-Aquitaine",
  },

  {
    value: 11,
    label: "Occitanie",
  },

  {
    value: 12,
    label: "Pays de la Loire",
  },

  {
    value: 13,
    label: "Provence-Alpes-Côte d’Azur",
  },

  {
    value: 14,
    label: "Guadeloupe",
  },

  {
    value: 15,
    label: "Martinique",
  },

  {
    value: 16,
    label: "Mayotte",
  },

  {
    value: 17,
    label: "Nouvelle-Calédonie",
  },
  {
    value: 18,
    label: "Polynésie française",
  },
  {
    value: 19,
    label: "La Réunion",
  },
  {
    value: 20,
    label: "Saint-Pierre et Miquelon",
  },
  {
    value: 21,
    label: "Saint-Martin",
  },
  {
    value: 22,
    label: "Saint-Barthélemy",
  },
];
export default AddSession;

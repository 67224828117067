import React from "react";
// react component that copies the given text inside your clipboard
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Button,
  Col
} from "reactstrap";
import Select from "react-select";
// core components
import Header from "components/Headers/UserHeader";
import Datetime from 'react-datetime';
import { API_ISE } from "../../variables/config";
import Label from "reactstrap/lib/Label";
import 'moment/locale/fr';
import Dropzone from "dropzone";
class AddSession extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Nom: "",
        Prenom: "",
        Phone: "",
        Email: "",
        password: "",
        Fonction : "",
        Description : "",
        Region : false,
        Mairie : false,
        Inter : false,
        Departement : false,
    load: false,
    err: "",
    photoUrl:''
    };
    this.dataEdit = this.dataEdit.bind(this);
  }
 
  async componentDidMount() {

      let currentSingleFile = undefined;
    Dropzone.autoDiscover = false;
    // single dropzone file - accepts only images
    new Dropzone(document.getElementById("dropzone-single"), {
      url:  "https://api.formations-des-elus.fr/files/upload/",
      thumbnailWidth: null,
      thumbnailHeight: null,
      dictDefaultMessage: "Télécharger une photo de profile",
      previewsContainer: document.getElementsByClassName(
        "dz-preview-single"
      )[0],
      previewTemplate: document.getElementsByClassName("dz-preview-single")[0]
        .innerHTML,
      maxFiles: 1,
      acceptedFiles: "image/*",
      success: (file, response) => {
        console.log(response.result.secure_url );
        this.setState({ photoUrl: response.result.secure_url });
      },
      init: function () {
        this.on("addedfile", (file) => {
          if (currentSingleFile) {
            this.removeFile(currentSingleFile);
          }
          currentSingleFile = file;
        });
      },
    });
    document.getElementsByClassName("dz-preview-single")[0].innerHTML = "";
  }

  dataEdit = () => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        Nom: this.state.Nom,
        Prenom: this.state.Prenom,
        Phone: this.state.Phone,
        Email: this.state.Email,
        password: this.state.Password,
        Fonction : this.state.Fonction,
        Description : this.state.Description,
        Region : this.state.Region,
        Mairie : this.state.Mairie,
        Inter : this.state.Inter,
        Departement : this.state.Departement,
        Avatar : this.state.photoUrl,
      }),
    };
    fetch(API_ISE + "/formateurs/new/", requestOptions)
      .then((response) => response.json())
      .then((data) => this.props.history.push("/admin/formateurs"));
  };
  
  render() {
    return (
      <>
        <Header
          form={this.state.formations}
          cat={this.state.cat}
          reference={this.state.references}
        />
        {/* Page content */}
        <Container className=" mt--7" fluid>
          {/* Table */}
          <Row>
            <div className=" col">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-0">Nouveeau formateur</h3>
                    </div>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Row>
                    <div className="col-lg-6">
                      
                      <FormGroup className="col-lg-12">
                        <Label for="TitleLabel">Nom</Label>
                        <Input
                          type="text"
                          name="Nom"
                          placeholder="Nom"
                          id="Nom"
                          onChange={(e) =>
                            this.setState({ Nom: e.target.value })
                          }
                        />
                      </FormGroup>
                      <FormGroup className="col-lg-12">
                        <Label for="TitleLabel">Prénom</Label>
                        <Input
                          type="text"
                          name="Prenom"
                          placeholder="Prénom"
                          id="Prenom"
                          onChange={(e) =>
                            this.setState({ Prenom: e.target.value })
                          }
                        />
                      </FormGroup>
                      <FormGroup className="col-lg-12">
                        <Label for="TitleLabel">Email</Label>
                        <Input
                          type="text"
                          name="Email"
                          placeholder="Email"
                          id="Email"
                          onChange={(e) =>
                            this.setState({ Email: e.target.value })
                          }
                        />
                      </FormGroup>
                      <FormGroup className="col-lg-12">
                        <Label for="TitleLabel">Mot de passe</Label>
                        <Input
                          type="text"
                          name="Password"
                          placeholder="Mot de passe"
                          id="Password"
                          onChange={(e) =>
                            this.setState({ Password: e.target.value })
                          }
                        />
                      </FormGroup>
                      <FormGroup className="col-lg-12">
                        <Label for="TitleLabel">Téléphone</Label>
                        <Input
                          type="text"
                          name="Phone"
                          placeholder="Téléphone"
                          id="Phone"
                          onChange={(e) =>
                            this.setState({ Phone: e.target.value })
                          }
                        />
                      </FormGroup>
                      <FormGroup className="col-lg-12">
                        <Label for="TitleLabel">Fonction</Label>
                        <Input
                          type="text"
                          name="Fonction"
                          placeholder="Fonction"
                          id="Fonction"
                          onChange={(e) =>
                            this.setState({ Fonction: e.target.value })
                          }
                        />
                      </FormGroup>
                      <FormGroup className="col-lg-12">
                        <Label for="TitleLabel">Description</Label>
                        <Input
                          type="textarea"
                          name="Titre"
                          placeholder="Description du formateur"
                          id="TitleLabel"
                          defaultValue={this.state.Lieu}
                          onChange={(e) =>
                            this.setState({ Places: e.target.value })
                          }
                        />
                      </FormGroup>
                      
                    </div>
                    <div className="col-lg-6">
                    <Label for="TitleLabel">Choisissez un accès</Label>
                      <div className="custom-control custom-control-alternative custom-checkbox">
                        <input
                          className="custom-control-input border-dark"
                          id="Mairie"
                          type="checkbox"
                          checked={this.state.Mairie ? true : false}
                          onChange={() =>
                            this.setState(
                              {
                                Mairie: !this.state.Mairie
                              }
                            )
                          }
                        />
                        <label
                          className="custom-control-label mb-2"
                          htmlFor="Mairie"
                        >
                          <span className="text-muted">Mairie</span>
                        </label>
                      </div>
                      <div className="custom-control custom-control-alternative custom-checkbox">
                        <input
                          className="custom-control-input border-dark"
                          id="Departement"
                          type="checkbox"
                          checked={this.state.Departement ? true : false}
                          onChange={() =>
                            this.setState(
                              {
                                Departement: !this.state.Departement
                              }
                            )
                          }
                        />
                        <label
                          className="custom-control-label mb-2"
                          htmlFor="Departement"
                        >
                          <span className="text-muted">Département</span>
                        </label>
                      </div>
                      <div className="custom-control custom-control-alternative custom-checkbox">
                        <input
                          className="custom-control-input border-dark"
                          id="Inter"
                          type="checkbox"
                          checked={this.state.Inter ? true : false}
                          onChange={() =>
                            this.setState(
                              {
                                Inter: !this.state.Inter
                              }
                            )
                          }
                        />
                        <label
                          className="custom-control-label mb-2"
                          htmlFor="Inter"
                        >
                          <span className="text-muted">Intercommunalités</span>
                        </label>
                      </div>
                      <div className="custom-control custom-control-alternative custom-checkbox">
                        <input
                          className="custom-control-input border-dark"
                          id="Region"
                          type="checkbox"
                          checked={this.state.Region ? true : false}
                          onChange={() =>
                            this.setState(
                              {
                                Region: !this.state.Region
                              }
                            )
                          }
                        />
                        <label
                          className="custom-control-label mb-2"
                          htmlFor="Region"
                        >
                          <span className="text-muted">Région</span>
                        </label>
                      </div>
                      <FormGroup className="col-lg-12">
                      <div
                          className="dropzone dropzone-single mb-3 col-6 btn-danger"
                          id="dropzone-single"
                        >
                          <div className="fallback ">
                            <div className="custom-file btn-danger">
                              <input
                                className="custom-file-input btn-danger"
                                id="projectCoverUploads"
                                type="file"
                                name="file"
                              />
                              <label
                                className="custom-file-label"
                                htmlFor="projectCoverUploads "
                              >
                                Télécharger une photo de profile
                              </label>
                            </div>
                          </div>
                          <div className="dz-preview dz-preview-single">
                            <div className="dz-preview-cover">
                              <img
                                alt="..."
                                className="dz-preview-img avatar avatar-lg"
                                data-dz-thumbnail=""
                              />
                            </div>
                          </div>
                        </div>
                      </FormGroup>
                    </div>
                    
                  </Row>
                  <div className="text-center">
                    <Button
                      className="mt-4 "
                      color="success"
                      type="button"
                      onClick={() => this.dataEdit()}
                    >
                      Enregistrer
                    </Button>
                  </div>
                </CardBody>
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}
const categories = [
 
  {
    value: 2,
    label: "Auvergne-Rhône-Alpes",
  },
  {
    value: 3,
    label: "Bretagne",
  },
  {
    value: 1,
    label: "Bourgogne-Franche-Comté",
  },
  {
    value: 4,
    label: "Centre-Val de Loire",
  },
  {
    value: 5,
    label: "Corse",
  },
  {
    value: 6,
    label: "Grand-Est",
  },
  {
    value: 7,
    label: "Hauts-de-France",
  },
  {
    value: 8,
    label: "Ile-de-France",
  },
  {
    value: 9,
    label: "Normandie",
  },
  {
    value: 10,
    label: "Nouvelle-Aquitaine",
  },

  {
    value: 11,
    label: "Occitanie",
  },

  {
    value: 12,
    label: "Pays de la Loire",
  },

  {
    value: 13,
    label: "Provence-Alpes-Côte d’Azur",
  },

  {
    value: 14,
    label: "Guadeloupe",
  },

  {
    value: 15,
    label: "Martinique",
  },

  {
    value: 16,
    label: "Mayotte",
  },

  {
    value: 17,
    label: "Nouvelle-Calédonie",
  },
  {
    value: 18,
    label: "Polynésie française",
  },
  {
    value: 19,
    label: "La Réunion",
  },
  {
    value: 20,
    label: "Saint-Pierre et Miquelon",
  },
  {
    value: 21,
    label: "Saint-Martin",
  },
  {
    value: 22,
    label: "Saint-Barthélemy",
  },
];
export default AddSession;


import React from "react";
// react component that copies the given text inside your clipboard
import { CopyToClipboard } from "react-copy-to-clipboard";
// reactstrap components
import {
  Card,
  CardHeader,
  Media,
  Container,
  Row,
  Table,
  Button
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import { Link } from "react-router-dom";
import { API_ISE } from "../../variables/config"
import moment from "moment"
class Icons extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      activeNav: 1,
      chartExample1Data: "data1",
      cat : "",
      formations : "",
      references : "",
      events : []
    };
  }
  deleteForm =(e)=>{
    console.log(e)
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ 
        EventID : e._id
     })
  };
  fetch(API_ISE+"/formations/delete-event/", requestOptions)
      .then(response => response.json())
      .then(data => alert("Fiche supprimé") , this.getData());
  }
 async getData(){
 await fetch(API_ISE+"/formations/all-stats")
  .then(res => res.json())
  .then(
    (result) => {
      console.log(result)
      this.setState({
        cat : result.c,
        formations : result.f,
        references : result.r,
        events : result.events
      })
    },
  )
  }
 async componentDidMount(){
  this.getData()
  }

  render() {
    const { events } = this.state;
    const EventsDates = events.sort(
      (a, b) => new Date(a.Dates) - new Date(b.Dates)
    );
    return (
      <>
        <Header form={this.state.formations} cat={this.state.cat} reference={this.state.references}/>
        {/* Page content */}
        <Container className=" mt--7" fluid>
          {/* Table */}
          <Row>
            <div className=" col">
            <Card className="shadow">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col">
                    <h3 className="mb-0">Dates et sessions</h3>
                    </div>
                    <div className="col text-right">
                      <Link
                        className="btn-success btn btn-sm"
                        to="/admin/ajouter-session"
                      >
                        Ajouter une session
                      </Link>
                    </div>
                  </Row>
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Région</th>
                      <th scope="col">Ville</th>
                      <th scope="col">Dates</th>
                      <th scope="col">Formation</th>
                      <th scope="col">Places</th>
                      <th scope="col">Modifier</th>
                      <th scope="col">Supprimer</th>
                    </tr>
                  </thead>
                  <tbody>
                    {EventsDates.map((item, i)=>{
                      return (
                        <tr key={i}>
                          <td>{item.Regions}</td>
                          <td>{item.Ville}</td>
                          <td>{moment(item.Dates).format('DD.MM.YYYY')}</td>
                          <td>{item.Formation}</td>
                          <td>{item.Places}</td>
                          <td>
                            <Link className="btn-info btn btn-sm" to={{
                          pathname: '/admin/modifier-session',
                          state: {
                            data: item
                          }
                        }}>
                              Modifier
                            </Link>
                          </td>
                          <td>
                            <Button className="btn-danger btn btn-sm" onClick={()=>this.deleteForm(item)}>
                              Supprimer
                            </Button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

export default Icons;


import React from "react";
// react component that copies the given text inside your clipboard
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Button
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import { Link } from "react-router-dom";
import { API_ISE } from "../../variables/config"
import Label from "reactstrap/lib/Label";

class EditCat extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      catName : props.location.state.data.CatLabel,
      imageIcone : props.location.state.data.Image,
      Catalogue : props.location.state.data.Catalogue
    };
    this.dataEdit = this.dataEdit.bind(this)
  }

  dataEdit=()=>{
    fetch(API_ISE+"/formations/edit-cat?CatID="+this.props.location.state.data._id+"&CatLabel="+this.state.catName+"&Image="+this.state.imageIcone+"&Catalogue="+this.state.Catalogue)
      .then(res => res.json())
      .then(
        (result) => {
          console.log(result)
          this.props.history.push('/admin/categories')
        },
      )
  
  }

  render() {
    console.log(this.state)
    return (
      <>
        <Header
          form={this.state.formations}
          cat={this.state.cat}
          reference={this.state.references}
        />
        {/* Page content */}
        <Container className=" mt--7" fluid>
          {/* Table */}
          <Row>
            <div className=" col">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-0">Modifier catégorie</h3>
                    </div>
                    <div className="col text-right">
                      <Link
                        className="btn-success btn btn-sm"
                        to="/admin/formations"
                      >
                        Enregistrer
                      </Link>
                    </div>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Form role="form">
                    <Row>
                      <FormGroup className="col-lg-4">
                        <Label for="TitleLabel">Nom de la catégorie</Label>
                        <Input
                          type="text"
                          name="TitleLabel"
                          placeholder="Catégorie"
                          id="TitleLabel"
                          defaultValue={this.state.catName}
                          onChange={(e)=>this.setState({catName : e.target.value})}
                        />
                      </FormGroup>
                      <FormGroup className="col-lg-4">
                        <Label for="SubTitleLabel">Picto</Label>
                        <Input
                          type="text"
                          name="SubTitleLabel"
                          placeholder="Logo"
                          id="SubTitleLabel"
                          defaultValue={this.state.imageIcone}
                          onChange={(e)=>this.setState({imageIcone : e.target.value})}
                        />
                      </FormGroup>
                      <FormGroup className="col-lg-4">
                        <Label for="SubTitleLabel">Catalogue</Label>
                        <Input
                          type="text"
                          name="SubTitleLabel"
                          placeholder="Lien catalogue"
                          id="SubTitleLabel"
                          defaultValue={this.state.Catalogue}
                          onChange={(e)=>this.setState({Catalogue : e.target.value})}
                        />
                      </FormGroup>
                    </Row>
                    <div className="text-center">
                      <Button className="mt-4" color="success" type="button" onClick={()=> this.dataEdit()}>
                        Modifier
                      </Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

export default EditCat;

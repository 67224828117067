import React from "react";
// react component that copies the given text inside your clipboard
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Button,
  Col
} from "reactstrap";
import Select from "react-select";
// core components
import Header from "components/Headers/UserHeader";
import Datetime from 'react-datetime';
import { API_ISE } from "../../variables/config";
import Label from "reactstrap/lib/Label";
import 'moment/locale/fr';
import Dropzone from "dropzone";
class AddSession extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Code : "",
      Amount : 0
    };
    this.dataEdit = this.dataEdit.bind(this);
  }
 
  handleChangeRegion = (region) => {
    console.log(region);
    this.setState({ Categorie: region.value });
  };
  handleChangeFormateur = (Formateur) => {
    console.log(Formateur);
    this.setState({ Formateur: Formateur.value });
  };
  dataEdit = () => {
    
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        Code: this.state.Code,
        Value: this.state.Amount,
        
      }),
    };
    fetch(API_ISE + "/api/add-code/", requestOptions)
      .then((response) => response.json())
      .then((data) => this.props.history.push("/admin/remises"));
  };
 
  render() {
    console.log(this.state.Match)
    return (
      <>
        <Header
          form={this.state.formations}
          cat={this.state.cat}
          reference={this.state.references}
        />
        {/* Page content */}
        <Container className=" mt--7" fluid>
          {/* Table */}
          <Row>
            <div className=" col">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-0">Nouvelle vidéo</h3>
                    </div>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Row>
                    <div className="col-lg-6">
                     
                      <FormGroup className="col-lg-12">
                        <Label for="TitleLabel">Montant</Label>
                        <Input
                          type="number"
                          name="Prix"
                          placeholder="Prix"
                          id="Prix"
                          defaultValue={this.state.Amount}
                          onChange={(e) =>
                            this.setState({ Amount: e.target.value })
                          }
                        />
                      </FormGroup>
                      <FormGroup className="col-lg-12">
                        <Label for="TitleLabel">Code</Label>
                        <Input
                          type="text"
                          name="Titre"
                          placeholder="Titre de la vidéo"
                          id="TitleLabel"
                          defaultValue={this.state.Code}
                          onChange={(e) =>
                            this.setState({ Code: e.target.value })
                          }
                        />
                      </FormGroup>
                      <Button
                      className="mt-4"
                      color="success"
                      type="button"
                      onClick={() => this.dataEdit()}
                    >
                      Enregistrer
                    </Button>
                    </div>
                    
                  </Row>
                 
                </CardBody>
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}
const acces = [
 
  {
    value: "Mairie",
    label: "Mairie",
  },
  {
    value: "Inter",
    label: "Intercommunalités",
  },
  {
    value: "Region",
    label: "Région",
  },
  {
    value: "Dep",
    label: "Département",
  }
];
export default AddSession;
